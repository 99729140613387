import React, { useState } from "react";
import './index.css'
import HrNavbar from '../HrNavbar'
import HrSidebar from '../HrSidebar'
import Url from '../../Url/Url'


const BlockProfileCreate = () => {

    const [blockUser, setBlockUser] = useState({
        profileImage: '',
        fullName: '',
        aadharCardNumber: '',
        panNumber: '',
        uniNumber: '',
        date: '',
        remarks: ''
    })


    const blockUserProfileInput = (event) => {
        setBlockUser({ ...blockUser, profileImage: event.target.files[0] })
    }

    const blockUserTextInput = (event) => {
        const { name, value } = event.target
        setBlockUser({ ...blockUser, [name]: value })

    }

    const addBlockUserSubmitBtn = async (event) => {
        event.preventDefault()
        const formData = new FormData()
        formData.append('profileImage', blockUser.profileImage)
        formData.append('fullName', blockUser.fullName)
        formData.append('aadharCardNumber', blockUser.aadharCardNumber)
        formData.append('panNumber', blockUser.panNumber)
        formData.append('uniNumber', blockUser.uniNumber)
        formData.append('date', blockUser.date)
        formData.append('remarks', blockUser.remarks)

        const url = `${Url}blockprofile/create/`
        const options = {
            method: 'POST',
            body: formData
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setBlockUser({
                ...blockUser,
                profileImage: '',
                fullName: '',
                aadharCardNumber: '',
                panNumber: '',
                uniNumber: '',
                date: '',
                remarks: ''
            });
            alert('Profile Added Successfull!')
            window.location.reload();
        }
        else {
            alert(results.profileImage)
        }
    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-3 p-1 py-4">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>Add Block Profile</b></h4>
                    <div className="hr-dashboard-recent-jobs-container m-2 my-4 p-2 py-3 p-md-4">
                        <h6 style={{ letterSpacing: '0.5px' }} className="p-1"><b>Post</b></h6>
                        <form className="d-flex row" onSubmit={addBlockUserSubmitBtn}>
                            <div className="col-md-6 my-2">
                                <label htmlFor="profileImage" className="m-1">Image</label>
                                <input type="file" name='profileImage' required onChange={blockUserProfileInput} className="form-control shadow-none " />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="fullName" className="m-1">Full Name</label>
                                <input type="text" name='fullName' value={blockUser.fullName} required onChange={blockUserTextInput} className="form-control shadow-none" placeholder='Enter FullName' />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="aadharCardNumber" className="m-1">Aadhar Number</label>
                                <input type="text" name='aadharCardNumber' value={blockUser.aadharCardNumber} required onChange={blockUserTextInput} className="form-control shadow-none" placeholder='Enter Aadhar Number' />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="panNumber" className="m-1">Pan Number</label>
                                <input type="text" name='panNumber' value={blockUser.panNumber} required onChange={blockUserTextInput} className="form-control shadow-none" placeholder='Enter Pan Number' />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="uniNumber" className="m-1">Uan Number</label>
                                <input type="number" name='uniNumber' value={blockUser.uniNumber} required onChange={blockUserTextInput} className="form-control shadow-none" placeholder='Enter Uan Number' />
                            </div>
                            <div className="col-md-6 my-2">
                                <label htmlFor="date" className="m-1">Date</label>
                                <input type="date" name='date' value={blockUser.date} required onChange={blockUserTextInput} className="form-control shadow-none" />
                            </div>
                            <div className="col-md-12 my-2">
                                <label htmlFor="remarks" className="m-1">Remarks</label>
                                <textarea name='remarks' rows={7} value={blockUser.remarks} required onChange={blockUserTextInput} className="form-control shadow-none" placeholder='Enter Remarks' />
                            </div>
                            <div className="my-3">
                                <button type="submit" className="btn btn-outline-primary btn-sm" >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlockProfileCreate