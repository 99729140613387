import React, { useEffect, useState } from "react";
import './index.css'
import AdminSidebar from '../AdminSidebar'
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../AdminFailureView'
import Url from '../../Url/Url'
import { MdDeleteForever } from "react-icons/md";

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const AdminContactUs = () => {

    const [contactData, setContactData] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingPodcastData = async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}contact/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedResults = [...results].sort((a, b) => b.id - a.id);
            setContactData(reversedResults)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }

    useEffect(() => {
        gettingPodcastData()
    }, [])

    const onDeleteContactBtn = async (deleteId) => {
        const url = `${Url}contact/delete/${deleteId}/`
        const options = {
            method: 'DELETE',
        }
        const response = await fetch(url, options)
        if (response.ok === true) {
            const filtedDelete = contactData?.filter((each) => each.id !== deleteId)
            setContactData(filtedDelete)
        }
    }

    const successView = () => {
        return (
            <div>
                {contactData?.map((each) => {
                    return (
                        <div style={{ borderRadius: "10px" }} className="bg-white mb-3 p-3" key={each.id}>
                            <div className="d-flex justify-content-between ">
                                <p className="my-1 text-primary ">Name : <span className="text-secondary">{each.firstName} {each.lastName}</span></p>
                                <MdDeleteForever onClick={() => { window.confirm('Are you sure you want to delete?',) && onDeleteContactBtn(each.id) }} color="red" size={22} style={{ cursor: 'pointer' }} className="m-1" />
                            </div>
                            <p className="my-1 text-primary ">Email : <span className="text-secondary">{each.emailId}</span></p>
                            <p className="my-1 text-primary ">Mobile Number : <span className="text-secondary">{each.mobileNumber}</span></p>
                            <p className="my-1 text-primary "><span className="text-secondary">{each.content}</span></p>
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderingApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="admin-db-container d-flex">
            <>
                <AdminSidebar />
            </>
            <div className="col-lg-10 col-12  admin-db-main-items-container">
                <div className="pt-5 pt-lg-0 mt-2 mt-lg-0">
                    <h3 className="m-4"><b>Contact Us</b></h3>
                    {renderingApiStatus()}
                </div>

            </div>
        </div>
    )
}
export default AdminContactUs
