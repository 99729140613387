import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './components/Home';
import HrMain from './pages/HrFrom/HrMain';
import HrJobPostMain from './pages/HrJobPostingFrom/HrJobPostMain';
import TpoMain from './pages/TPO/TpoMain';
import Ex from './components/Ex.js'
import StudentFile from './pages/StudentForm/StudentFile'
import { BrowserRouter as BR, Routes, Route } from 'react-router-dom';
import Login from './components/Login/index.js';
import AdminDashboard from './admin/AdminDashboard/index.js';
import AdminHr from './admin/AdminHr/index.js';
import AdminStudent from './admin/AdminStudent/index.js';
import AdminTpo from './admin/AdminTpo/index.js';
import Footer from './components/Footer/index.js';
import AboutUs from './components/AboutUs/index.js';
import ContactUs from './components/ContactUs/index.js';
import CurrentJob from './components/CurrentJob/index.js';
import AdminLogin from './admin/AdminLogin/index.js';
import AdminHrView from './admin/AdminHrView/index.js';
import AdminStudentView from './admin/AdminStudentView'
import AdminTpoView from './admin/AdminTpoView'
import AdminPodcast from './admin/AdminPodcast/index.js';
import AdminProtectedRoute from './admin/AdminProtectedRoute'
import HrLogin from './HrComponent/HrLogin/index.js';
import HrDashboard from './HrComponent/HrDashboard/index.js';
import HrJobsAll from './HrComponent/HrJobsAll/index.js';
import HrJobView from './HrComponent/HrJobView/index.js';
import HrProfileView from './HrComponent/HrProfileView'
import BlockProfile from './HrComponent/BlockProfile/index.js';
import HrProtectedRoute from './HrComponent/HrProtectedRoute/index.js';
import StudentLogin from './StudentComponent/StudentLogin/index.js';
import StudentDashboard from './StudentComponent/StudentDashboard/index.js';
import StudentJobs from './StudentComponent/StudentJobs/index.js';
import AdminCareers from './admin/AdminCareers/index.js';
import AdminHrJobView from './admin/AdminHrJobView/index.js';
import BlockProfileCreate from './HrComponent/BlockProfileCreate/index.js';
import StudentJobView from './StudentComponent/StudentJobView/index.js';
import StudentProfileView from './StudentComponent/StudentProfileView/index.js';
import StudentProtectedRoute from './StudentComponent/StudentProtectedRoute/index.js';
import TpoLogin from './TpoComponent/TpoLogin/index.js';
import TpoDashboard from './TpoComponent/TpoDashboard/index.js';
import TpoPost from './TpoComponent/TpoPost/index.js';
import TpoProfileView from './TpoComponent/TpoProfileView/index.js';
import TpoManagePost from './TpoComponent/TpoManagePost/index.js';
import TpoProtectedRoute from './TpoComponent/TpoProtectedRoute'
import HrTpo from './HrComponent/HrTpo/index.js';
import AdminContactUs from './admin/AdminContactUs/index.js';
import HrAllApplicants from './HrComponent/HrAllApplicants/index.js';
import HrAllApplicantsData from './HrComponent/HrAllApplicantsData/index.js';
import HrApplicantView from './HrComponent/HrApplicantView/index.js';

function App() {

  return (
    <div className='App'>
      <BR>
        <Routes>
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<AboutUs />} />
          <Route exact path='/contact' element={<ContactUs />} />
          <Route exact path='/current_job' element={<CurrentJob />} />
          <Route exact path='/hr-register' element={<HrMain />} />
          <Route exact path='/student-register' element={<StudentFile />} />
          <Route exact path='/tpo-register' element={<TpoMain />} />
          <Route exact path='/ex' element={<Ex />} />
          <Route exact path='/footer' element={<Footer />} />
          {/* Admin Component */}
          <Route exact path='/admin-login' element={<AdminLogin />} />
          <Route exact path='/admin-dashboard' element={<AdminProtectedRoute Component={AdminDashboard} />} />
          <Route exact path='/admin-hr' element={<AdminProtectedRoute Component={AdminHr} />} />
          <Route exact path='/admin-student' element={<AdminProtectedRoute Component={AdminStudent} />} />
          <Route exact path='/admin-tpo' element={<AdminProtectedRoute Component={AdminTpo} />} />
          <Route exact path='/admin-podcast' element={<AdminProtectedRoute Component={AdminPodcast} />} />
          <Route exact path='/hr/:id' element={<AdminProtectedRoute Component={AdminHrView} />} />
          <Route exact path='/student/:id' element={<AdminProtectedRoute Component={AdminStudentView} />} />
          <Route exact path='/tpo/:id' element={<AdminProtectedRoute Component={AdminTpoView} />} />
          <Route exact path='/admin/careers' element={<AdminProtectedRoute Component={AdminCareers} />} />
          <Route exact path='/admin/hr/job/:id' element={<AdminProtectedRoute Component={AdminHrJobView} />} />
          <Route exact path='/admin/contact' element={<AdminProtectedRoute Component={AdminContactUs} />} />
          {/* Hr Component */}
          <Route exact path='/hr/login' element={<HrLogin />} />
          <Route exact path='/hr/dashboard' element={<HrProtectedRoute Component={HrDashboard} />} />
          <Route exact path='/hr/post/job' element={<HrProtectedRoute Component={HrJobPostMain} />} />
          <Route exact path='/hr/all/jobs' element={<HrProtectedRoute Component={HrJobsAll} />} />
          <Route exact path='/hr/job/details/:id' element={<HrProtectedRoute Component={HrJobView} />} />
          <Route exact path='/hr/profile' element={<HrProtectedRoute Component={HrProfileView} />} />
          <Route exact path='/blockprofile' element={<HrProtectedRoute Component={BlockProfile} />} />
          <Route exact path='/blockprofile/create' element={<HrProtectedRoute Component={BlockProfileCreate} />} />
          <Route exact path='/hr/tpo/posts' element={<HrProtectedRoute Component={HrTpo} />} />
          <Route exact path='/hr/all/applicants' element={<HrProtectedRoute Component={HrAllApplicants} />} />
          <Route exact path='/hr/all/applicants/details/:id' element={<HrProtectedRoute Component={HrAllApplicantsData} />} />
          <Route exact path='/hr/applicant/view/:id' element={<HrProtectedRoute Component={HrApplicantView} />} />
          {/* Student Component */}
          <Route exact path='/student/login' element={<StudentLogin />} />
          <Route exact path='/student/dashboard' element={<StudentProtectedRoute Component={StudentDashboard} />} />
          <Route exact path='/student/all/jobs' element={<StudentProtectedRoute Component={StudentJobs} />} />
          <Route exact path='/student/job/details/:id' element={<StudentProtectedRoute Component={StudentJobView} />} />
          <Route exact path='/student/profile' element={<StudentProtectedRoute Component={StudentProfileView} />} />
          {/* Tpo Component */}
          <Route exact path='/tpo/login' element={<TpoLogin />} />
          <Route exact path='/tpo/dashboard' element={<TpoProtectedRoute Component={TpoDashboard} />} />
          <Route exact path='/tpo/post' element={<TpoProtectedRoute Component={TpoPost} />} />
          <Route exact path='/tpo/profile' element={<TpoProtectedRoute Component={TpoProfileView} />} />
          <Route exact path='/tpo/all/posts' element={<TpoProtectedRoute Component={TpoManagePost} />} />
        </Routes>
      </BR>

    </div>
  );
}

export default App;
