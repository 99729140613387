import React, { useCallback, useEffect, useState } from "react";
import './index.css'
import Images from "../../Images";
import DOMPurify from 'dompurify';
import { IoBriefcaseOutline, IoMailOutline, IoCallOutline, IoBookOutline, IoLocationOutline, IoTimeOutline, IoCalendarClearOutline, IoTimerOutline, IoMapOutline, IoCheckboxOutline } from "react-icons/io5";
import { GiSandsOfTime } from "react-icons/gi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import { useParams } from "react-router-dom";
import Url from '../../Url/Url'
import AdminFailureView from "../../admin/AdminFailureView";
import PageLoader from "../../Loaders/PageLoader";
import { useNavigate } from "react-router-dom";
import StudentNavbar from "../StudentNavbar";
import StudentSidebar from "../StudentSidebar";
import Modal from 'react-bootstrap/Modal';
import Cookies from "js-cookie";
import FormLoader from "../../Loaders/FormLoader";

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const StudentJobView = () => {

    const { id } = useParams()
    const [hrJobView, setHrJobView] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [apiStatus1, setApiStatus1] = useState(currentApiStatuses.initial)
    const navigate = useNavigate()

    const gettingHrDshboarddata = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}hr/jobposts/${id}/`
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setHrJobView(results)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }

    }, [id])

    useEffect(() => {
        gettingHrDshboarddata()
    }, [gettingHrDshboarddata])

    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }
    const getJobResponsibilitiesData = (jobResponsibilities) => {
        const jobResponsibilitiesClean = DOMPurify.sanitize(jobResponsibilities);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: jobResponsibilitiesClean }} />
    }
    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }
    const getCompanyDescriptionData = (companyDescription) => {
        const companyDescriptionClean = DOMPurify.sanitize(companyDescription);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: companyDescriptionClean }} />
    }
    const getHowtoApplyData = (howtoApply) => {
        const howtoApplyClean = DOMPurify.sanitize(howtoApply);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: howtoApplyClean }} />
    }


    const gettingDatePosted = (date) => {
        const formattedDate = date && date.slice(8, 10) + '-' + date.slice(5, 7) + '-' + date.slice(0, 4);
        return formattedDate
    }

    const [showCorrect, setShowCorrect] = useState(false);
    const [jobId, setJobId] = useState()
    const [applayJobData, seteApplayJobData] = useState({
        student_name: '',
        resume: '',
        cover_letter: '',
        job_posting: '',
        student: ''
    })

    useEffect(() => {
        const student_login_token = Cookies.get('student_login_token')
        if (student_login_token) {
            const studentTokenId = JSON.parse(student_login_token)
            seteApplayJobData({ ...applayJobData, student: studentTokenId.id, job_posting: jobId })
        }
    }, [jobId, applayJobData])

    const applyStudentJobInput = (event) => {

        const { name } = event.target
        if (name === 'student_name') {
            seteApplayJobData({ ...applayJobData, student_name: event.target.value })
        }
        else if (name === 'resume') {
            seteApplayJobData({ ...applayJobData, resume: event.target.files[0] })
        }
        else if (name === 'cover_letter') {
            seteApplayJobData({ ...applayJobData, cover_letter: event.target.value })
        }

    }

    const jobSubmitBtn = async (event) => {
        event.preventDefault()
        setApiStatus1(currentApiStatuses.inProgress)
        const formData = new FormData()
        formData.append('student_name', applayJobData.student_name)
        formData.append('resume', applayJobData.resume)
        formData.append('cover_letter', applayJobData.cover_letter)
        formData.append('job_posting', applayJobData.job_posting)
        formData.append('student', applayJobData.student)

        const url = `${Url}apply_for_job/${jobId}/`
        const options = {
            method: 'POST',
            body: formData
        }
        const response = await fetch(url, options)

        if (response.ok === true) {
            setApiStatus1(currentApiStatuses.success)
            setTimeout(() => {
                navigate('/student/all/jobs')
            }, 3000)
        }
        else {
            setApiStatus1(currentApiStatuses.failure)
        }

    }

    const initialView1 = () => {
        return (
            <form className="w-100 p-md-4 p-2" onSubmit={jobSubmitBtn}>
                <div className="my-1">
                    <label htmlFor="student_name" className="mx-1">Name</label>
                    <input type="text" required className="form-control w-100 shadow-none " onChange={applyStudentJobInput} name="student_name" placeholder='Enter Your Name' />
                </div>
                <div className="my-1">
                    <label htmlFor="resume" className="mx-1">Resume PDF</label>
                    <input type="file" required className="form-control w-100 shadow-none" onChange={applyStudentJobInput} name="resume" placeholder='Enter Your Name' />
                </div>
                <div className="my-1">
                    <label htmlFor="cover_letter" className="mx-1">Content</label>
                    <textarea required className="form-control w-100" rows={2} name="cover_letter" onChange={applyStudentJobInput} placeholder='Enter Content' />
                </div>
                <button type="submit" className="btn btn-outline-primary btn-sm">Submit</button>
            </form>
        )
    }

    const successView1 = () => {
        return (
            <>
                <img className='w-25' src={Images.checkCorrect} alt='Correct' />
                <h6 className='correct-text'>You have Successfully Applied </h6>
            </>
        )
    }

    const failureView1 = () => {
        return (
            <>
                <img src={Images.something_went_wrong} className="w-100 abc" alt="img" />
                <button type="button" onClick={() => { navigate('/') }} className="btn btn-warning btn-sm">Retry</button>
            </>
        )
    }

    const rednerApiStatusApply = () => {
        switch (apiStatus1) {
            case currentApiStatuses.inProgress:
                return <FormLoader />
            case currentApiStatuses.success:
                return <>{successView1()}</>
            case currentApiStatuses.failure:
                return <>{failureView1()}</>
            default:
                return <>{initialView1()}</>
        }
    }

    const showingPopup = () => {
        return (
            <Modal show={showCorrect} className=' ' centered >
                <Modal.Body className=' d-flex flex-column justify-content-center align-items-center model-height'>
                    {rednerApiStatusApply()}
                </Modal.Body>
            </Modal>
        )
    }

    const successView = () => {
        return (
            <>
                {hrJobView && (
                    <>
                        <div className="hr-view-details-title-container position-relative ">
                            <div className="hr-view-details-title-img-container position-absolute ">
                                <img src={Images.hr_view} alt="img" className="hr-view-details-title-img" />
                            </div>
                            <div className="hr-view-details-title-heading-container d-flex justify-content-center align-items-center  position-absolute ">
                                <h3 className="hr-view-details-title-heading">{hrJobView.jobTitle}</h3>
                            </div>
                        </div>
                        <div className="hr-view-details-company-card p-3 d-lg-flex justify-content-between  align-items-center ">
                            <div className="d-md-flex align-items-center ">
                                <div>
                                    <img src={hrJobView.companylogo} alt="img" className="hr-view-details-company-card-img" />
                                </div>
                                <div className=" py-3 p-md-3">
                                    <h5>{hrJobView.jobTitle}</h5>
                                    <div className="d-md-flex">
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0 ">
                                            <IoBriefcaseOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">{hrJobView.companyName}</p>
                                        </div>
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0">
                                            <IoLocationOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">{hrJobView.state}, {hrJobView.district}</p>
                                        </div>
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0">
                                            <IoTimeOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">1 day ago</p>
                                        </div>
                                    </div>
                                    <div className="d-md-flex">
                                        <button className="hr-view-details-employement-type my-2">{hrJobView.employmentType}</button>
                                        <button className="hr-view-details-department-type my-2 mx-3">{hrJobView.department}</button>
                                        <button className="hr-view-details-shift-type my-2">{hrJobView.shiftType}</button>
                                    </div>
                                </div>
                            </div>
                            <>
                                <button className="hr-job-view-apply-for-job-btn" onClick={() => {
                                    setShowCorrect(true)
                                    setJobId(hrJobView.id)
                                }}>
                                    Apply Now
                                    <svg fill="currentColor" viewBox="0 0 24 24" className="hr-job-view-apply-for-job-btn-icon">
                                        <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </>
                        </div>
                        <div className="d-md-flex">
                            <div className="p-3 col-lg-8">
                                <div className="my-4">
                                    <h6 className="my-3"><b>Job Description</b></h6>
                                    {getJobDescriptionData(hrJobView.jobDescription)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>Job Responsibilities</b></h6>
                                    {getJobResponsibilitiesData(hrJobView.jobResponsibilities)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>Skill & Experience</b></h6>
                                    {getSkillsRequiredData(hrJobView.skillsRequired)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>About company</b></h6>
                                    {getCompanyDescriptionData(hrJobView.companyDescription)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>How to Apply</b></h6>
                                    {getHowtoApplyData(hrJobView.howtoApply)}
                                </div>
                            </div>
                            <div className="col-lg-4  ">
                                <div className="he-view-job-overview-container px-4 py-3 my-md-5">
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCalendarClearOutline color="#1967d2" size={22} className="mx-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Date Posted<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{gettingDatePosted(hrJobView.approved_date)}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <GiSandsOfTime color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Expiration date<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.applicationDeadline}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBookOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Qualification<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.qualifications}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBriefcaseOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Job Title<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.jobTitle}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCheckboxOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Department<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.department}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCallOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Phone<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.contactPhone}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoMailOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Email<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.contactEmail}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoLocationOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Location<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.state}, {hrJobView.district}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoMapOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Address<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.address}</span></p>
                                    </div>
                                </div>
                                <div className="he-view-job-overview-container px-4 py-3 my-5">
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBriefcaseOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Name<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.companyName}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <HiOutlineBuildingLibrary color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Level<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.companyLevel}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoTimerOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Founded in<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrJobView.foundedIn}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <CgWebsite color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Website<br /><a href={hrJobView.websiteUrl} target="_blank" rel="noreferrer" className="text-primary" style={{ fontSize: '14px' }} >{hrJobView.websiteUrl}</a></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {showingPopup(hrJobView.id)}
                    </>
                )}
            </>
        )
    }

    const renderApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <StudentNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <StudentSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-view-details-container p-md-4 p-1 py-4">
                    {renderApiStatus()}
                </div>
            </div>
        </div >
    )
}
export default StudentJobView