import React, { useEffect, useState } from "react";
import './index.css'
import { Link } from "react-router-dom";
import { IoHomeOutline, IoBriefcaseOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";

const StudentSidebar = () => {
    const [sidebarLocation, setSidebarLocation] = useState()

    useEffect(() => {
        setSidebarLocation(window.location.pathname)
    }, [])

    return (
        <div className="student-sidebar-main-container py-4 px-3 col-lg-2 ">
            <Link to='/student/dashboard' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/dashboard' ? 'active_path' : ''}`}>
                <IoHomeOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                <h6 className="student-sidebar-items-pre m-0">Dashboard</h6>
            </Link>
            <Link to='/student/all/jobs' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/all/jobs' ? 'active_path' : ''}`}>
                <IoBriefcaseOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                <h6 className="student-sidebar-items-pre m-0">Jobs</h6>
            </Link>
            {/* <Link to='/student/applied/jobs' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/applied/jobs' ? 'active_path' : ''}`}>
                <IoDocumentTextOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                <h6 className="student-sidebar-items-pre m-0">Applied Jobs</h6>
            </Link>
            <div className="d-flex student-sidebar-list-item my-1">
                <IoBookmarkOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                <h6 className="student-sidebar-items-pre m-0">Shortlisted</h6>
            </div> */}
            <Link to='/student/profile' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/profile' ? 'active_path' : ''}`}>
                <HiOutlineUsers color="#474d6a" className="student-sidebar-list-icon" size={20} />
                <h6 className="student-sidebar-items-pre m-0">My Profile</h6>
            </Link>
        </div>
    )
}
export default StudentSidebar