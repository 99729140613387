import React, { useEffect, useState } from 'react'
import TpoSidebar from '../TpoSidebar'
import TpoNavbar from '../TpoNavbar'
import Cookies from 'js-cookie'
import Url from '../../Url/Url'

const employmentType = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: 'Fulltime', value: 'Fulltime' },
    { id: 2, text: 'Part-Time', value: 'PartTime' },
    { id: 3, text: 'Casual', value: 'Casual' },
    { id: 4, text: 'Fixed-term contract', value: 'Fixedterm' },
    { id: 5, text: 'Apprenticeship', value: 'Apprenticeship' },
    { id: 6, text: 'Traineeship', value: 'Traineeship' },
    { id: 7, text: 'Internship', value: 'Internship' },
]

const qualifications = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: '10th', value: '10th' },
    { id: 2, text: 'Inter', value: 'Inter' },
    { id: 3, text: 'Any Degree', value: 'Any Degree' },
    { id: 4, text: 'B-Tech', value: 'B-Tech' },
    { id: 5, text: 'Mba', value: 'Mba' },
    { id: 6, text: 'Mca', value: 'Mca' },
    { id: 7, text: 'Msc', value: 'Msc' },
]

const TpoPost = () => {
    const [tpoTokenData, setTpoTokenData] = useState()
    const [tpoData, setTpoData] = useState({
        tpo: '',
        collegeName: '',
        collegeAddress: '',
        qualification: '',
        branch: '',
        noOfStudent: '',
        jobType: '',
    })

    useEffect(() => {
        const tpo_login_token = Cookies.get('tpo_login_token')
        if (tpo_login_token) {
            const tpoCookieData = JSON.parse(tpo_login_token)
            setTpoTokenData(tpoCookieData)
        }
    }, [])


    const gettingData = (event) => {
        const { name, value } = event.target
        setTpoData({ ...tpoData, [name]: value, tpo: tpoTokenData.id })
    }
    const tpoFetchSubmitBtn = async (event) => {
        event.preventDefault()
        const url = `${Url}tpo/create_post/${tpoTokenData.id}/`
        const options = {
            method: "POST",
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(tpoData)
        };
        const response = await fetch(url, options)

        if (response.ok === true) {
            alert('Successfull !')
            setTpoData({
                ...tpoData,
                tpo: '',
                collegeName: '',
                collegeAddress: '',
                qualification: '',
                branch: '',
                noOfStudent: '',
                jobType: '',
            })
        }
        else {
            alert('Fail !')
        }
    }
    return (
        <div className="vh-100 w-100">
            <TpoNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <TpoSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-3 p-1 py-4">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>Tpo Post</b></h4>
                    <form className='p-2 d-flex row' onSubmit={tpoFetchSubmitBtn}>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='collegeName' className='m-1'>College Name</label>
                            <input type='text' className='form-control shadow-none ' name='collegeName' value={tpoData.collegeName} onChange={gettingData} required placeholder='Enter College Name' />
                        </div>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='collegeAddress' className='m-1'>College Address</label>
                            <input type='text' className='form-control shadow-none ' name='collegeAddress' value={tpoData.collegeAddress} onChange={gettingData} required placeholder='Enter College Address' />
                        </div>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='qualification' className='m-1'>Qualification</label>
                            <select className='form-select shadow-none' name='qualification' value={tpoData.qualification} onChange={gettingData} required>
                                {qualifications?.map((each) => {
                                    return (
                                        <option key={each.value} value={each.value} >{each.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='branch' className='m-1'>Branch</label>
                            <input type='text' className='form-control shadow-none ' name='branch' value={tpoData.branch} onChange={gettingData} required placeholder='Enter Branch' />
                        </div>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='noOfStudent' className='m-1'>Students</label>
                            <input type='text' className='form-control shadow-none ' name='noOfStudent' value={tpoData.noOfStudent} onChange={gettingData} required placeholder='Enter Number of Students' />
                        </div>
                        <div className='col-lg-6 col-12 my-2'>
                            <label htmlFor='jobType' className='m-1'>Job Type</label>
                            <select className='form-select shadow-none' name='jobType' value={tpoData.jobType} onChange={gettingData} required>
                                {employmentType?.map((each) => {
                                    return (
                                        <option key={each.value} value={each.value} >{each.text}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div className='my-3'>
                            <button type='submit' className='btn btn-outline-primary btn-sm'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}

export default TpoPost