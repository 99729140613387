import React, { useState, useEffect } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const companyLevel = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: '1', value: '1' },
    { id: 2, text: '2', value: '2' },
    { id: 3, text: '3', value: '3' },
    { id: 4, text: '4', value: '4' },
    { id: 5, text: '5', value: '5' },
    { id: 6, text: 'Other', value: 'Other' },
]

const CompanyDetails = (props) => {
    const { validatingFormValid, addFormData } = props
    const [pic, setPic] = useState()

    const companyDetailsSchema = Yup.object().shape({
        companyName: Yup.string().required('Company Name is a required field'),
        companyLevel: Yup.string().required('Company Level is a required field'),
        foundedIn: Yup.string().required('Company Founded is a required field'),
        companylogo: Yup.mixed()
            .required()
            .test('fileType', 'Please upload an image like jpeg, jpg, png, or mpeg are allowed', (value) => {
                if (!value || !value[0]) {
                    return false;
                }

                const fileExtension = value[0].name.split('.').pop().toLowerCase();
                const validTypes = ['jpeg', 'jpg', 'png', 'mpeg'];

                const fileType = fileExtension;
                return validTypes.includes(fileType);
            }),
        companyDescription: Yup.string().required('Job Description is a required field')
            .test('Job Description is a required field', 'Job Description is a required field', (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            }),
        howtoApply: Yup.string().required('Job Description is a required field')
            .test('Job Description is a required field', 'Job Description is a required field', (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            }),

        websiteUrl: Yup.string().url('Enter Valid URl').required('Website URL is a required field'),
    })
    const companyDetailsResolver = { resolver: yupResolver(companyDetailsSchema) }
    const { register, handleSubmit, control, formState } = useForm(companyDetailsResolver)
    const { errors } = formState

    const setFromData = (data) => {
        addFormData('companyDetails', data)
    }

    useEffect(() => {
        validatingFormValid('companyDetails', formState.isValid)
    }, [formState.isValid, validatingFormValid])


    return (
        <form className="container" id="companyDetails-details-form" onSubmit={handleSubmit((data) => { setFromData(data) })}>
            <div className="personal-details-container p-md-2 py-3 py-md-3 pb-2 ">
                <h4 className="mb-3 mx-md-3 text-dark">Company Details</h4>
                <div className="hr-personal-details-container1 text-dark p-md-3 d-flex row">
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="companyName" className="form-label">Company Name<span className="text-danger"> *</span></label>
                        <input {...register('companyName')} placeholder="Enter Company Name" type="text" name="companyName" className={`form-control ${errors.companyName ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.companyName?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="companyLevel" className="form-label">Company Level<span className="text-danger"> *</span></label>
                        <select {...register('companyLevel')} name="companyLevel" className={`form-select ${errors.companyLevel ? 'is-invalid' : ''}`} >
                            {companyLevel?.map((each) => {
                                return (
                                    <option value={each.value} key={each.id}>{each.text}</option>
                                )
                            })}
                        </select>
                        <p className="text-danger">{errors?.companyLevel?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="foundedIn" className="form-label">Company Founded<span className="text-danger"> *</span></label>
                        <input {...register('foundedIn')} placeholder="Enter Company Founded" type="text" name="foundedIn" className={`form-control ${errors.foundedIn ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.foundedIn?.message}</p>
                    </div>
                    <div className='mb-2 col-xl-6 col-lg-6 '>
                        <div className="d-flex">
                            <div className={`${!pic ? 'col-xl-12 w-100' : 'col-xl-10 '}`}>
                                <label htmlFor="companylogo" className="form-label">
                                    Profile pic<span className="text-danger"> *</span>
                                </label>
                                <input
                                    {...register("companylogo", {
                                        onChange: (e) => {
                                            setPic(!pic && URL.createObjectURL(e.target.files[0]));
                                        },
                                    })}
                                    accept="image/*"
                                    name="companylogo"
                                    type="file"
                                    className={`form-control ${errors.companylogo ? "is-invalid" : ""
                                        }`}
                                />
                            </div>
                            <div className={`${!pic ? 'd-none' : 'col-xl-2'}`}>
                                {!pic ? (''
                                ) : (
                                    <img src={pic} className="profile-pic" alt="profile" />
                                )}
                            </div>
                        </div>
                        <p className="text-danger">
                            {errors?.companylogo?.message}
                        </p>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="companyDescription" className="form-label">Company Description<span className="text-danger"> *</span></label>
                        <div >
                            <Controller
                                name="companyDescription"
                                control={control}
                                render={({ field }) => (
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={(content) => field.onChange(content)}
                                        className='text-dark quill-container'
                                        placeholder={"Enter Job Description"}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errors?.companyDescription?.message}</p>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="howtoApply" className="form-label">How to Apply<span className="text-danger"> *</span></label>
                        <div >
                            <Controller
                                name="howtoApply"
                                control={control}
                                render={({ field }) => (
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={(content) => field.onChange(content)}
                                        className='text-dark quill-container'
                                        placeholder={"Enter Job Description"}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errors?.howtoApply?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="websiteUrl" className="form-label">Website URL<span className="text-danger"> *</span></label>
                        <input {...register('websiteUrl')} placeholder="Enter Website URL" type="text" name="websiteUrl" className={`form-control ${errors.websiteUrl ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.websiteUrl?.message}</p>
                    </div>

                </div>
            </div>
        </form>
    )
}
export default CompanyDetails