import React, { useEffect, useState } from "react";
import './index.css'
import HrNavbar from '../HrNavbar'
import HrSidebar from '../HrSidebar'
import Cookies from "js-cookie";
import States from '../../pages/States.json'
import Url from '../../Url/Url'
import Industries from '../../pages/Industries.json'
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'
import FormLoader from "../../Loaders/FormLoader";
import Images from "../../Images";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const genderData = [
    { id: 1, value: 'male', label: 'Male' },
    { id: 2, value: 'feMale', label: 'FeMale' },
    { id: 3, value: 'others', label: 'Others' },
]

const companyStrengthDetails = [
    { id: 1, text: "Small", value: "Small" },
    { id: 2, text: "Medium", value: "Medium" },
    { id: 3, text: "Large", value: "Large" },
];

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}


const HrProfileView = () => {

    const [hrData, setHrData] = useState()
    const [updatedData, setUpdatedData] = useState({})
    const [hrProfileId, setHrProfileId] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [apiStatusUpdate, setApiStatusUpdate] = useState(currentApiStatuses.initial)
    const [showCorrect, setShowCorrect] = useState(false);
    const navigate = useNavigate()


    const gettingHrprofile = async (hrId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}hr/${hrId}/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setHrData(results)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }

    useEffect(() => {
        const hrTokenObj = Cookies.get('hr_login_token');
        if (hrTokenObj) {
            const hrToken = JSON.parse(hrTokenObj);
            gettingHrprofile(hrToken.id);
            setHrProfileId(hrToken.id);
        }
    }, []);



    const profileUpdateInputFields = (event) => {
        const { name, value } = event.target
        setUpdatedData({ ...updatedData, [name]: value })
    }


    const successView = () => {
        return (
            <div className="">
                <h4 style={{ letterSpacing: '1px' }} className="p-0 m-2"><b>Profile</b></h4>
                <div className="hr-profile-view-inputs-container p-md-2 p-2 my-3">
                    <h6><b>My Profile</b></h6>
                    {hrData && (
                        <form className="my-4" onSubmit={onSubmitedUpdatedData}>
                            <img src={hrData.profilePic} alt="Profile Pic" className="hr-profile-view-pic" />
                            <div className="d-flex row">
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Full Name</label>
                                    <input type="text" name="fullName" placeholder={hrData.fullName} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Gender</label>
                                    <select name="gender" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={hrData.gender}>{hrData.gender}</option>
                                        {genderData?.map((each) => (
                                            each?.value !== hrData?.gender && (
                                                <option key={each.value} value={each.value}>
                                                    {each.label}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Date of Birth</label>
                                    <input type="date" name="dateOfBirth" placeholder={hrData.dateOfBirth} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">State</label>
                                    <select name="state" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={hrData.state}>{hrData.state}</option>
                                        {States.states?.map((each) => (
                                            each?.value !== hrData?.state && (
                                                <option key={each.value} value={each.value}>
                                                    {each.state}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">District</label>
                                    <input type="text" name="district" placeholder={hrData.district} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Pincode</label>
                                    <input type="number" name="pincode" placeholder={hrData.pincode} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Email</label>
                                    <input disabled type="email" name="email" placeholder={hrData.email} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Mobile Number</label>
                                    <input type="text" name="mobileNumber" placeholder={hrData.mobileNumber} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Organization Name</label>
                                    <input type="text" name="oraganizationName" placeholder={hrData.oraganizationName} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Industry</label>
                                    <select name="industry" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={hrData.industry}>{hrData.industry}</option>
                                        {Industries?.map((each) => (
                                            each?.value !== hrData?.industry && (
                                                <option key={each.value} value={each.value}>
                                                    {each.label}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Department</label>
                                    <input type="text" name="department" placeholder={hrData.department} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Designation</label>
                                    <input type="text" name="designation" placeholder={hrData.designation} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Company URL</label>
                                    <input type="text" name="companyUrl" placeholder={hrData.companyUrl} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Working Location</label>
                                    <input type="text" name="workingLocation" placeholder={hrData.workingLocation} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Company Strength</label>
                                    <select name="companyStrength" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={hrData.companyStrength}>{hrData.companyStrength}</option>
                                        {companyStrengthDetails?.map((each) => (
                                            each?.value !== hrData?.companyStrength && (
                                                <option key={each.value} value={each.value}>
                                                    {each.value}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">EmployeeId</label>
                                    <input type="text" name="employeeId" placeholder={hrData.employeeId} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Experience</label>
                                    <input type="text" name="experience" placeholder={hrData.experience} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Official Email</label>
                                    <input disabled type="email" name="officialEmail" placeholder={hrData.officialEmail} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-sm mt-3 btn-outline-primary" >Submit</button>
                        </form>
                    )}

                </div>
            </div>
        )
    }

    const renderApiStatusView = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    const successViewUpdate = () => {
        return (
            <>
                <img className='w-25' src={Images.checkCorrect} alt='Correct' />
                <h6 className='correct-text'>You have Successfully Updated </h6>
                <button className="btn btn-success btn-sm my-3" onClick={() => navigate('/hr/dashboard')} >Home</button>
            </>
        )
    }

    const failureViewUpdate = () => {
        return (
            <>
                <img src={Images.something_went_wrong} className="w-100 abc" alt="img" />
                <button type="button" onClick={() => { navigate('/hr/dashboard') }} className="btn btn-warning btn-sm">Retry</button>
            </>
        )
    }

    const formRenderview = () => {
        switch (apiStatusUpdate) {
            case currentApiStatuses.inProgress:
                return <FormLoader />
            case currentApiStatuses.success:
                return <>{successViewUpdate()}</>
            case currentApiStatuses.failure:
                return <>{failureViewUpdate()}</>
            default:
                return null
        }
    }

    const showingPopup = () => {
        return (
            <Modal show={showCorrect} className=' ' centered >
                <Modal.Body className='text-center d-flex flex-column justify-content-center align-items-center model-height'>
                    {formRenderview()}
                </Modal.Body>
            </Modal>
        )
    }

    const onSubmitedUpdatedData = async (event) => {
        event.preventDefault()
        if (Object.keys(updatedData).length !== 0) {
            setShowCorrect(true)
            setApiStatusUpdate(currentApiStatuses.inProgress)
            const url = `${Url}hr/update/${hrProfileId}/`
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData)
            }
            const response = await fetch(url, options)

            if (response.ok === true) {
                setApiStatusUpdate(currentApiStatuses.success)
            }
            else {
                setApiStatusUpdate(currentApiStatuses.failure)
            }
        }
        else {
            alert('No Changes Made')
        }

    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-4 p-1 py-4">
                    {renderApiStatusView()}
                    {showingPopup()}
                </div>
            </div>
        </div >
    )
}
export default HrProfileView