import React, { useEffect, useCallback, useState } from "react";
import './index.css'
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import { useParams, Link } from "react-router-dom/dist";
import HrNavbar from "../HrNavbar";
import HrSidebar from "../HrSidebar";
import { MdDownloadForOffline } from "react-icons/md";
import { IoIosOpen } from "react-icons/io";
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../../admin/AdminFailureView";

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}


const HrAllApplicantsData = () => {
    const [applicantsData, setApplicantsData] = useState()
    const { id } = useParams()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingStudentAppliedJobs = useCallback(async (hrId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}hr/get_applied_posts/${hrId}/`
        const options = {
            method: "GET"
        }
        const response = await fetch(url, options)
        const results = await response.json()

        if (response.ok === true) {
            setApplicantsData(results)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [])



    useEffect(() => {
        const hr_login_token = Cookies.get('hr_login_token')
        if (hr_login_token) {
            const hrData = JSON.parse(hr_login_token)
            gettingStudentAppliedJobs(hrData.id)
        }

    }, [gettingStudentAppliedJobs])

    const gettingPostDays = (jobPostDate) => {
        const formattedDate = jobPostDate && jobPostDate.slice(8, 10) + '-' + jobPostDate.slice(5, 7) + '-' + jobPostDate.slice(0, 4);
        return formattedDate
    };

    const handleDownloadClick = (resume) => {
        const link = document.createElement('a');
        link.href = resume;
        link.setAttribute('download', 'resume.pdf'); // Set the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const successView = () => {
        return (
            <div className="hr-alljobs-container m-2 my-4 p-0 py-1 p-md-3">
                <div className="hr-dashboard-recent-jobs-container1 bg-transparent  m-2  p-md-0">
                    <div className="px-lg-2 px-1 row" >
                        {
                            applicantsData?.filter(each => each.job_posting_id === parseInt(id))
                                .map(job => job.applicants.map((applicant) => {
                                    return (
                                        <div key={applicant.id} style={{ color: '#474d6a', textDecoration: 'none' }} className="mb-3 col-lg-6 p-2 ">
                                            <div className="hr-alljobs-items-container p-3 bg-white">
                                                <h6>Full Name : {applicant.student_name}</h6>
                                                <p className="mb-2">Date of Applied : {gettingPostDays(applicant.application_date)}</p>
                                                <p className="d-flex m-0">Resume :
                                                    <MdDownloadForOffline className="mx-2" size={20} color="blue" onClick={() => { handleDownloadClick(applicant.resume) }} />
                                                </p>
                                                <Link to={`/hr/applicant/view/${applicant.student}`} className="d-flex justify-content-end " > <IoIosOpen size={20} color="green" /></Link>

                                            </div>
                                        </div>
                                    )
                                }))
                        }


                    </div>
                </div>
            </div>
        )
    }

    const rednerApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-3 p-1 py-4">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>All Applicants</b></h4>
                    {rednerApiStatus()}
                </div>
            </div>
        </div >
    )
}
export default HrAllApplicantsData