import React, { useEffect, useState } from "react";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import States from '../States.json'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const employmentType = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: 'Fulltime', value: 'Fulltime' },
    { id: 2, text: 'Part-Time', value: 'PartTime' },
    { id: 3, text: 'Casual', value: 'Casual' },
    { id: 4, text: 'Fixed-term contract', value: 'Fixedterm' },
    { id: 5, text: 'Apprenticeship', value: 'Apprenticeship' },
    { id: 6, text: 'Traineeship', value: 'Traineeship' },
    { id: 7, text: 'Internship', value: 'Internship' },
]

const qualifications = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: '10th', value: '10th' },
    { id: 2, text: 'Inter', value: 'Inter' },
    { id: 3, text: 'Any Degree', value: 'Any Degree' },
    { id: 4, text: 'B-Tech', value: 'B-Tech' },
    { id: 5, text: 'Mba', value: 'Mba' },
    { id: 6, text: 'Mca', value: 'Mca' },
    { id: 7, text: 'Msc', value: 'Msc' },
]
const shiftType = [
    { id: 0, text: 'Select...', value: '' },
    { id: 1, text: 'Standard Shift', value: 'Standard Shift' },
    { id: 2, text: 'Fixed Shift', value: 'Fixed Shift' },
    { id: 3, text: 'Rotating Shift', value: 'Rotating Shift' },
    { id: 4, text: 'Flex Shift', value: 'Flex Shift' },
    { id: 5, text: 'Compressed Shift', value: 'Compressed Shift' },
    { id: 6, text: 'Split Shift', value: 'Split Shift' },
    { id: 7, text: 'On-call Shift', value: 'On-call Shift' },
    { id: 8, text: 'Weekday Shift', value: 'Weekday Shift' },
    { id: 9, text: 'Weekend Shift', value: 'weekend Shift' },
]

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/



const JobDetails = (props) => {
    const { addFormData, validatingFormValid } = props
    const [districtItem1, setDistrictItem1] = useState()
    //this is state
    const [selectedDate] = useState(null);

    //Date functionality
    const minDate = () => {
        const today = new Date().toISOString().split('T')[0];
        return today;
    };

    const jobPostSchema = Yup.object().shape({
        jobTitle: Yup.string().required('Job Title is a required field'),
        jobDescription: Yup.string().required('Job Description is a required field')
            .test('Job Description is a required field', 'Job Description is a required field', (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            }),
        department: Yup.string().required('Department is a required field'),
        state: Yup.string().required('State is a required field'),
        district: Yup.string().required('District is a required field'),
        address: Yup.string().required('Address is a required field'),
        employmentType: Yup.string().required('Employment Type is a required field'),
        shiftType: Yup.string().required('Shift Type is a required field'),
        salaryFrom: Yup.number()
            .typeError('Salary Range Level is a required field')
            .required()
            .min(1000, "Too little"),
        salaryEnd: Yup.number()
            .typeError('Salary Range Level is a required field')
            .required()
            .max(5000000, 'Very High!'),
        experienceLevel: Yup.number().required().typeError('Experience Level is a required field'),
        educationLevel: Yup.string().required('Education Level is a required field'),
        qualifications: Yup.string().required('Qualifications is a required field'),
        contactPerson: Yup.string().required('Contact is a required field'),
        contactEmail: Yup.string().email().required('Email is a required field'),
        contactPhone: Yup.string().required('Phone Number a required field').matches(phoneRegExp, 'Phone number is not valid').min(10, 'num must be 10 digit').max(10, 'num must be 10 digit'),
        applicationDeadline: Yup.string()
            .transform(function (value, originalValue) {
                if (this.isType(value)) {
                    return value;
                }
                const result = parseInt(originalValue, "dd.MM.yyyy", new Date());
                return result;
            })
            .typeError("Please enter a valid date")
            .required(),
        // .min("1969-11-13", "Date is too early"),
        jobResponsibilities: Yup.string().required('Job Description is a required field')
            .test('Job Description is a required field', 'Job Description is a required field', (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            }),
        skillsRequired: Yup.string().required('Job Description is a required field')
            .test('Job Description is a required field', 'Job Description is a required field', (value) => {
                if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
                    return false;
                }
                return true;
            }),
    })
    const HrJobPostFormOptions = { resolver: yupResolver(jobPostSchema) }
    const { register, handleSubmit, control, formState } = useForm(HrJobPostFormOptions)
    const { errors } = formState


    const stateOnChange = (stateValue) => {
        if (!stateValue) {
            setDistrictItem1([]);
        } else {
            const foundState = States.states && States.states.find((eachState) => eachState.state === stateValue);

            if (foundState) {
                setDistrictItem1(foundState.districts);
            } else {
                setDistrictItem1([]);
            }
        }
    };


    const setFromData = (data) => {
        addFormData('jobDetails', data)
    }

    useEffect(() => {
        validatingFormValid('jobDetails', formState.isValid)
    }, [formState.isValid, validatingFormValid])


    return (
        <form className="container " id="jobDetails-details-form" onSubmit={handleSubmit((data) => { setFromData(data) })}>
            <div className="personal-details-container p-md-2 py-3 py-md-3 pb-2 ">
                <h4 className="mb-3 mx-md-3  text-dark">Job Details</h4>
                <div className="hr-personal-details-container1 text-dark p-0 p-md-3 d-flex row">
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="jobTitle" className="form-label">Job Title<span className="text-danger"> *</span></label>
                        <textarea {...register('jobTitle')} name="jobTitle" className={`form-control ${errors.jobTitle ? 'is-invalid' : ''}`} rows={4} placeholder="Enter Job Title" />
                        <p className="text-danger">{errors?.jobTitle?.message}</p>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="jobDescription" className="form-label">Job Description<span className="text-danger"> *</span></label>
                        <div >
                            <Controller
                                name="jobDescription"
                                control={control}
                                rules={{ required: 'Job description is required' }}
                                render={({ field }) => (
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={(content) => field.onChange(content)}
                                        className='text-dark quill-container'
                                        placeholder={"Enter Job Description"}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errors?.jobDescription?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="department" className="form-label">Department<span className="text-danger"> *</span></label>
                        <input {...register('department')} placeholder="Enter Department" type="text" name="department" className={`form-control ${errors.department ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.department?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-2">
                        <label htmlFor="state" className="form-label">State<span placeholder="Select..." className="error-and-error-para-text">*</span></label>
                        <select {...register('state', {
                            onChange: (e) => { stateOnChange(e.target.value) },
                        })} name="state" className={`form-select ${errors.state ? 'is-invalid' : ''}`} aria-label="Default select example">
                            {States.states && States.states.map((eachState, index) => {
                                return (
                                    <option key={index} value={eachState.value}>{eachState.state}</option>
                                )
                            })}
                        </select>
                        <p className="error-and-error-para-text">{errors?.state?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-2">
                        <label htmlFor="district" className="form-label">District<span className="error-and-error-para-text">*</span></label>
                        <select {...register('district')} name="district" className={`form-select ${errors.district ? 'is-invalid' : ''}`} aria-label="Default select example">
                            {!districtItem1 && <option value=''>Select...</option>}
                            {districtItem1 && districtItem1.map((eachDis, index) => {
                                return <option key={index} value={eachDis}>{eachDis}</option>
                            })}
                        </select>
                        <p className="error-and-error-para-text">{errors?.district?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="address" className="form-label">Address<span className="text-danger"> *</span></label>
                        <input {...register('address')} placeholder="Enter Address" type="text" name="address" className={`form-control ${errors.address ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.address?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="employmentType" className="form-label">Employment Type<span className="text-danger"> *</span></label>
                        <select {...register('employmentType')} name="employmentType" className={`form-select ${errors.employmentType ? 'is-invalid' : ''}`} >
                            {employmentType?.map((each) => {
                                return (
                                    <option value={each.value} key={each.id}>{each.text}</option>
                                )
                            })}
                        </select>
                        <p className="text-danger">{errors?.employmentType?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="shiftType" className="form-label">Shift Type<span className="text-danger"> *</span></label>
                        <select {...register('shiftType')} name="shiftType" className={`form-select ${errors.shiftType ? 'is-invalid' : ''}`} >
                            {shiftType?.map((each) => {
                                return (
                                    <option value={each.value} key={each.id}>{each.text}</option>
                                )
                            })}
                        </select>
                        <p className="text-danger">{errors?.employmentType?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3 form-group d-flex">
                        <div className="col-6">
                            <label htmlFor="salaryFrom" className="form-label">Salary From<span className="text-danger"> *</span></label>
                            <input {...register('salaryFrom')} aria-label="salaryFrom" placeholder="Enter Salary From" type="number" name="salaryFrom" className={`form-control  ${errors.salaryFrom ? 'is-invalid' : ''}`} />
                            <p className="text-danger">{errors?.salaryFrom?.message}</p>
                        </div>
                        <div className="col-6">
                            <label htmlFor="salaryEnd" className="form-label">Salary End<span className="text-danger"> *</span></label>
                            <input {...register('salaryEnd')} aria-label="salaryEnd" placeholder="Enter Salary End" type="number" name="salaryEnd" className={`form-control ${errors.salaryEnd ? 'is-invalid' : ''}`} />
                            <p className="text-danger">{errors?.salaryEnd?.message}</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="experienceLevel" className="form-label">Experience<span className="text-danger"> *</span></label>
                        <input {...register('experienceLevel')} placeholder="Enter Experience" type="number" name="experienceLevel" className={`form-control ${errors.experienceLevel ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.experienceLevel?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="educationLevel" className="form-label">Education Level<span className="text-danger"> *</span></label>
                        <input {...register('educationLevel')} placeholder="Enter Education Level" type="text" name="educationLevel" className={`form-control ${errors.educationLevel ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.educationLevel?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="qualifications" className="form-label">Qualifications<span className="text-danger"> *</span></label>
                        <select {...register('qualifications')} name="qualifications" className={`form-select ${errors.qualifications ? 'is-invalid' : ''}`} >
                            {qualifications?.map((each) => {
                                return (
                                    <option value={each.value} key={each.id}>{each.text}</option>
                                )
                            })}
                        </select>
                        <p className="text-danger">{errors?.qualifications?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="contactPerson" className="form-label">Contact Person<span className="text-danger"> *</span></label>
                        <input {...register('contactPerson')} placeholder="EnterContact Person" type="text" name="contactPerson" className={`form-control ${errors.contactPerson ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.contactPerson?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="contactEmail" className="form-label">Contact Email<span className="text-danger"> *</span></label>
                        <input {...register('contactEmail')} placeholder="Enter Contact Email" type="text" name="contactEmail" className={`form-control ${errors.contactEmail ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.contactEmail?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="contactPhone" className="form-label">Contact Phone<span className="text-danger"> *</span></label>
                        <input {...register('contactPhone')} placeholder="Enter Contact Phone" type="number" name="contactPhone" className={`form-control ${errors.contactPhone ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.contactPhone?.message}</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 mb-3">
                        <label htmlFor="applicationDeadline" className="form-label">Application Deadline<span className="text-danger"> *</span></label>
                        <input {...register('applicationDeadline')} value={selectedDate}
                            min={minDate()}
                            placeholder="Enter Application Deadline" type="date" name="applicationDeadline"
                            className={`form-control ${errors.applicationDeadline ? 'is-invalid' : ''}`} />
                        <p className="text-danger">{errors?.applicationDeadline?.message}</p>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="jobResponsibilities" className="form-label">Job Responsibilities<span className="text-danger"> *</span></label>
                        <div >
                            <Controller
                                name="jobResponsibilities"
                                control={control}
                                rules={{ required: 'Job description is required' }}
                                render={({ field }) => (
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={(content) => field.onChange(content)}
                                        className='text-dark quill-container'
                                        placeholder={"Enter Job Responsibilities "}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errors?.jobResponsibilities?.message}</p>
                    </div>
                    <div className="col-xl-12 mb-3">
                        <label htmlFor="skillsRequired" className="form-label">Skills Required<span className="text-danger"> *</span></label>
                        <div >
                            <Controller
                                name="skillsRequired"
                                control={control}
                                rules={{ required: 'Job description is required' }}
                                render={({ field }) => (
                                    <ReactQuill
                                        theme="snow"
                                        value={field.value}
                                        onChange={(content) => field.onChange(content)}
                                        className='text-dark quill-container'
                                        placeholder={"Enter Skills"}
                                    />
                                )}
                            />
                        </div>
                        <p className="text-danger">{errors?.skillsRequired?.message}</p>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default JobDetails