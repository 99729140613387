import React, { useEffect, useState } from "react";
import './index.css'
import { FaRegUserCircle } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Url from '../../Url/Url'

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const HrLogin = () => {

    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const navigate = useNavigate()

    const rednerComponents = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return (
                    <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                )

            default:
                return 'Sign In'
        }
    }

    const hrLoginInput = (event) => {
        const { name, value } = event.target
        if (name === 'username') {
            setUsername(value)
        }
        else if (name === 'password') {
            setPassword(value)
        }
    }


    const signInSubmit = async (event) => {
        event.preventDefault()
        setApiStatus(currentApiStatuses.inProgress)
        const userDetails = { username, password }
        const url = `${Url}hr/login/`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails)
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setApiStatus(currentApiStatuses.initial)
            navigate('/hr/dashboard')
            Cookies.set('hr_login_token', JSON.stringify(results), { expires: 7 });
        }
        else {
            setApiStatus(currentApiStatuses.initial)
            setErrorMsg(results.message)
        }
    }

    useEffect(() => {
        const hr_login_token = Cookies.get('hr_login_token')
        if (hr_login_token) {
            navigate('/hr/dashboard')
        }
    }, [navigate])


    return (
        <div className="vh-100 w-100 hr-login-container ">
            <div className="hr-login-form-contianer col-xl-6 float-lg-end ">
                <form className="hr-login-main-container p-2" onSubmit={signInSubmit}>
                    <div>
                        <div className="hr-login-card-container">
                            <h3 className="hr-login-heading">HR LOGIN</h3>
                            <div>
                                <label htmlFor="username" className="form-label mx-3 mb-1">Username</label>
                                <div className="hr-login-input-container">
                                    <div className="hr-login-input-icon-container">
                                        <FaRegUserCircle className="hr-login-input-icon" />
                                    </div>
                                    <input name="username" onChange={hrLoginInput} className="hr-login-input" type="text" placeholder="Username" />
                                </div>
                            </div>
                            <div className="mt-4">
                                <label htmlFor="password" className="form-label mx-3 mb-1">Password</label>
                                <div className="hr-login-input-container">
                                    <div className="hr-login-input-icon-container">
                                        <RiLockPasswordLine className="hr-login-input-icon" />
                                    </div>
                                    <input name="password" onChange={hrLoginInput} className="hr-login-input" type="password" placeholder="Password" />
                                </div>
                            </div>
                            {errorMsg && <p className="text-danger px-3 m-0">{errorMsg}</p>}
                            <button type="submit" className="hr-login-btn" >
                                {rednerComponents()}
                            </button>
                            <p className="hr-or-login-para my-3">Or With</p>
                            <button type="button" className="hr-login-google-button">
                                <FcGoogle className="fs-5 mx-2" /> Log in with Google
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default HrLogin