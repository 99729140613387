import React, { useCallback, useEffect, useState } from "react";
import './index.css'
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import HrNavbar from "../HrNavbar";
import HrSidebar from "../HrSidebar";
import { BsCurrencyRupee } from "react-icons/bs";
import { IoLocationOutline, IoDocumentTextOutline } from "react-icons/io5";
import { BiBookmark } from "react-icons/bi";
import { TbBriefcase2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const HrAllApplicants = () => {
    const [hrJobList, setHrJobList] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingPostDays = (jobPostDate) => {
        const formattedDate = jobPostDate && jobPostDate.slice(8, 10) + '-' + jobPostDate.slice(5, 7) + '-' + jobPostDate.slice(0, 4);
        return formattedDate
    };

    const gettingActiveorNot = (deadline) => {
        const today = new Date().toJSON().slice(0, 10)
        const results = today > deadline
        return (
            <div className={`${results ? 'hr-jobpost-expired-text-div' : 'hr-jobpost-active-text-div'}`}>
                <p className=" my-0 ">
                    {results ? 'Expired' : 'Active'}
                </p>
            </div>
        )
    }

    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }

    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }

    const gettingHrDshboarddata = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const hr_login_token = Cookies.get('hr_login_token')
        const HrTokenObj = JSON.parse(hr_login_token)
        const url = `${Url}hr/dashboard/${HrTokenObj.id}/`
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedResults = [...results].sort((a, b) => b.id - a.id);
            setHrJobList(reversedResults)
            setApiStatus(currentApiStatuses.success)
            console.log(results)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }

    }, [])

    useEffect(() => {
        gettingHrDshboarddata()
    }, [gettingHrDshboarddata])

    const successView = () => {
        return (
            <>
                <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>All Applicants</b></h4>
                <div className="hr-alljobs-container m-2 my-4 p-0 py-1 p-md-3">
                    <div className="hr-dashboard-recent-jobs-container1 bg-transparent  m-2  p-md-0">
                        <div className="px-lg-2 px-1 row" >
                            {hrJobList?.map((each, index) => {
                                return (
                                    <Link to={`/hr/all/applicants/details/${each.id}`} key={index} style={{ color: '#474d6a', textDecoration: 'none' }} className="mb-3 col-lg-6 p-2 ">
                                        <div className="hr-alljobs-items-container p-3 bg-white">
                                            <div className="d-flex justify-content-between ">
                                                <div>
                                                    <h6 className="text-dark hr-alljobs-items-para1"><b>{each.jobTitle}</b></h6>
                                                    <h6 className="text-dark hr-alljobs-items-para1"><b>{each.companyName}</b></h6>
                                                </div>
                                                <div className="hr-alljobs-items-img-container">
                                                    <img src={each.companylogo} alt="img" className="hr-alljobs-items-img" />
                                                </div>
                                            </div>
                                            <div className="d-flex row my-2">
                                                <p className="hr-alljobs-items-para my-1 col-5 col-md-3">  <TbBriefcase2 size={19} /> <span className=" px-1"> {each.experienceLevel} Yrs </span></p>
                                                <p className="hr-alljobs-items-para my-1 px-2  col-7 col-md-4">  <BsCurrencyRupee size={19} /> <span className=" px-1"> {each.salaryFrom} - {each.salaryEnd} PA </span></p>
                                                <p className="hr-alljobs-items-para1 my-1 px-2 col-12 col-md-5">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.state}, {each.district}</span></p>
                                                <div className="d-flex my-1">
                                                    <div>
                                                        <IoDocumentTextOutline size={19} />
                                                    </div>
                                                    {getJobDescriptionData(each.jobDescription)}
                                                </div>
                                                <div className="d-flex my-1">
                                                    <div>
                                                        <BiBookmark size={19} />
                                                    </div>
                                                    {getSkillsRequiredData(each.skillsRequired)}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between hr-alljobs-items-days">
                                                <p className="text-secondary my-0 px-1">
                                                    {gettingPostDays(each.approved_date)}
                                                </p>
                                                <>
                                                    {gettingActiveorNot(each.applicationDeadline)}
                                                </>

                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-3 p-1 py-4">
                    {renderApiStatus()}
                </div>
            </div>
        </div >
    )
}
export default HrAllApplicants