import React, { useState } from "react";
import './index.css'
import AdminSidebar from "../AdminSidebar";
import Images from "../../Images";
import DOMPurify from 'dompurify';
import { IoBriefcaseOutline, IoMailOutline, IoCallOutline, IoBookOutline, IoLocationOutline, IoTimeOutline, IoCalendarClearOutline, IoTimerOutline, IoMapOutline, IoCheckboxOutline } from "react-icons/io5";
import { GiSandsOfTime } from "react-icons/gi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { CgWebsite } from "react-icons/cg";
import { useEffect } from "react";
import { useCallback } from "react";
import { useParams } from "react-router-dom/dist";
import Url from '../../Url/Url'


const AdminHrJobView = () => {

    const [hrViewData, setHrViewData] = useState()
    const { id } = useParams()

    const gettingJobDataView = useCallback(async () => {
        const url = `${Url}hr/jobposts/${id}/`
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setHrViewData(results)
        }

    }, [id])

    useEffect(() => {
        gettingJobDataView()
    }, [gettingJobDataView])


    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }
    const getJobResponsibilitiesData = (jobResponsibilities) => {
        const jobResponsibilitiesClean = DOMPurify.sanitize(jobResponsibilities);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: jobResponsibilitiesClean }} />
    }
    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }
    const getCompanyDescriptionData = (companyDescription) => {
        const companyDescriptionClean = DOMPurify.sanitize(companyDescription);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: companyDescriptionClean }} />
    }
    const getHowtoApplyData = (howtoApply) => {
        const howtoApplyClean = DOMPurify.sanitize(howtoApply);
        return <div className="hr-view-job-des-inner-html" dangerouslySetInnerHTML={{ __html: howtoApplyClean }} />
    }

    const gettingDatePosted = (date) => {
        const formattedDate = date.slice(8, 10) + '-' + date.slice(5, 7) + '-' + date.slice(0, 4);
        return formattedDate
    }

    return (
        <div className="admin-db-container d-flex">
            <>
                <AdminSidebar />
            </>
            <div className="col-lg-10 col-12 mt-5 py-4 mt-lg-0 py-lg-3  admin-db-main-items-container">
                {hrViewData && (
                    <>
                        <div className="hr-view-details-title-container position-relative ">
                            <div className="hr-view-details-title-img-container position-absolute ">
                                <img src={Images.hr_view} alt="img" className="hr-view-details-title-img" />
                            </div>
                            <div className="hr-view-details-title-heading-container d-flex justify-content-center align-items-center  position-absolute ">
                                <h3 className="hr-view-details-title-heading">{hrViewData.jobTitle}</h3>
                            </div>
                        </div>
                        <div className="hr-view-details-company-card p-3 d-lg-flex justify-content-between  align-items-center bg-white ">
                            <div className="d-md-flex align-items-center ">
                                <div>
                                    <img src={hrViewData.companylogo} alt="img" className="hr-view-details-company-card-img" />
                                </div>
                                <div className=" py-3 p-md-3 mx-3">
                                    <h5>{hrViewData.jobTitle}</h5>
                                    <div className="d-md-flex">
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0 ">
                                            <IoBriefcaseOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">{hrViewData.companyName}</p>
                                        </div>
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0">
                                            <IoLocationOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">{hrViewData.state}, {hrViewData.district}</p>
                                        </div>
                                        <div className="d-flex align-items-center text-secondary py-1 py-md-0">
                                            <IoTimeOutline className="m-0" size={20} />
                                            <p className="mx-2 m-0 hr-view-details-company-card-text">1 day ago</p>
                                        </div>
                                    </div>
                                    <div className="d-md-flex">
                                        <button className="hr-view-details-employement-type my-2">{hrViewData.employmentType}</button>
                                        <button className="hr-view-details-department-type my-2 mx-3">{hrViewData.department}</button>
                                        <button className="hr-view-details-shift-type my-2">{hrViewData.shiftType}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-md-flex bg-white">
                            <div className="p-4 col-lg-8">
                                <div className="my-4">
                                    <h6 className="my-3"><b>Job Description</b></h6>
                                    {getJobDescriptionData(hrViewData.jobDescription)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>Job Responsibilities</b></h6>
                                    {getJobResponsibilitiesData(hrViewData.jobResponsibilities)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>Skill & Experience</b></h6>
                                    {getSkillsRequiredData(hrViewData.skillsRequired)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>About company</b></h6>
                                    {getCompanyDescriptionData(hrViewData.companyDescription)}
                                </div>
                                <div className="my-4">
                                    <h6 className="my-3"><b>How to Apply</b></h6>
                                    {getHowtoApplyData(hrViewData.howtoApply)}
                                </div>
                            </div>
                            <div className="col-lg-4  p-3">
                                <div className="he-view-job-overview-container px-4 py-3 my-md-5">
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCalendarClearOutline color="#1967d2" size={22} className="mx-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Date Posted<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{gettingDatePosted(hrViewData.approved_date)}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <GiSandsOfTime color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Expiration date<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.applicationDeadline}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBookOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Qualification<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.qualifications}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBriefcaseOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Job Title<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.jobTitle}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCheckboxOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Department<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.department}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoCallOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Phone<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.contactPhone}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoMailOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Email<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.contactEmail}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoLocationOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Location<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.state}, {hrViewData.district}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoMapOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Address<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.address}</span></p>
                                    </div>
                                </div>
                                <div className="he-view-job-overview-container px-4 py-3 my-5">

                                    <div className="d-flex my-3">
                                        <div>
                                            <IoBriefcaseOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Name<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.companyName}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <HiOutlineBuildingLibrary color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Level<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.companyLevel}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <IoTimerOutline color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Founded in<br /><span className="text-secondary" style={{ fontSize: '14px' }} >{hrViewData.foundedIn}</span></p>
                                    </div>
                                    <div className="d-flex my-3">
                                        <div>
                                            <CgWebsite color="#1967d2" size={22} className="m-1 p-0" />
                                        </div>
                                        <p className="m-0 px-3">Website<br /><a href={hrViewData.websiteUrl} target="_blank" rel="noreferrer" className="text-primary" style={{ fontSize: '14px' }} >{hrViewData.websiteUrl}</a></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
export default AdminHrJobView