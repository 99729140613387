import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const StudentProtectedRoute = (props) => {
    const { Component } = props
    const navigate = useNavigate()

    useEffect(() => {
        const student_login_token = Cookies.get('student_login_token')
        if (!student_login_token) {
            navigate('/student/login')
        }
    }, [navigate])

    return (
        <>
            <Component />
        </>
    )
}
export default StudentProtectedRoute