import React, { useState, useCallback, useEffect } from "react";
import './index.css'
import HrSidebar from "../HrSidebar";
import HrNavbar from "../HrNavbar";
import Url from '../../Url/Url'
import { IoSchoolOutline, IoBookOutline, IoBookmarkOutline, IoBriefcaseOutline, IoLocationOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../../admin/AdminFailureView";


const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const HrTpo = () => {

    const [hrJobList] = useState()
    const [filterdHrJobList, setFilterdHrJobList] = useState()
    const [approvedStatus] = useState('true')
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [tpoData, setTpoData] = useState()


    const gettingTpoprofile = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}tpo/get_post/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedData = [...results].sort((a, b) => b.id - a.id)
            setTpoData(reversedData)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [])

    useEffect(() => {
        gettingTpoprofile()
    }, [gettingTpoprofile])


    useEffect(() => {
        const approvedJobsList = (hrJobList ?? []).filter((each) => {
            return approvedStatus === 'true' ? each.approved === true : each.approved === false;
        });
        setFilterdHrJobList(approvedJobsList)
    }, [approvedStatus, hrJobList, setFilterdHrJobList])

    const successView = () => {
        return (
            <>
                {tpoData?.map((each) => {
                    return (
                        <div key={each.id} className="col-lg-6 p-1">
                            <div className="bg-white  p-3 hr-tpo-manage-sub-card">
                                <p className="hr-alljobs-items-para1 my-1 ">  <IoSchoolOutline size={19} /> <span className=" px-1"> {each.collegeName} </span></p>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookOutline size={19} /> <span className=" px-1"> {each.branch} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookmarkOutline size={19} /> <span className=" px-1"> {each.qualification} </span></p>
                                </div>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBriefcaseOutline size={19} /> <span className=" px-1"> {each.jobType} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <FiUsers size={19} /> <span className=" px-1"> {each.noOfStudent} </span></p>
                                </div>
                                <p className="hr-alljobs-items-para1 my-1 col-12">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.collegeAddress} </span></p>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    const rednerApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-3 p-1 py-4">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>TPO Posts</b></h4>
                    <div className=" p-3  d-flex row">
                        {rednerApiStatus()}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default HrTpo