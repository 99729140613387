import React, { useEffect, useState } from "react";
import './index.css'
import { Link } from "react-router-dom";
import { IoHomeOutline, IoFileTrayOutline, IoSendOutline, IoBriefcaseOutline, IoBookmarkOutline, IoBanOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";

const HrSidebar = () => {
    const [sidebarLocation, setSidebarLocation] = useState()

    useEffect(() => {
        setSidebarLocation(window.location.pathname)
    }, [])

    return (
        <div className="hr-sidebar-main-container py-4 px-3 col-lg-2 ">
            <Link to='/hr/dashboard' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/dashboard' ? 'active_path' : ''}`}>
                <IoHomeOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Dashboard</h6>
            </Link>
            <Link to='/hr/post/job' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/post/job' ? 'active_path' : ''}`}>
                <IoSendOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Post Job</h6>
            </Link>
            <Link to='/hr/all/jobs' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/all/jobs' ? 'active_path' : ''}`}>
                <IoBriefcaseOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Manage Jobs</h6>
            </Link>
            <Link to='/hr/all/applicants' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/all/applicants' ? 'active_path' : ''}`}>
                <IoFileTrayOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">All Applicants</h6>
            </Link>
            <Link to='/hr/tpo/posts' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/tpo/posts' ? 'active_path' : ''}`}>
                <IoBookmarkOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">TPO</h6>
            </Link>
            <Link to='/hr/profile' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/profile' ? 'active_path' : ''}`}>
                <HiOutlineUsers color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">My Profile</h6>
            </Link>
            <Link to='/blockprofile' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/blockprofile' ? 'active_path' : ''}`}>
                <IoBanOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Block Profile</h6>
            </Link>
        </div>
    )
}
export default HrSidebar