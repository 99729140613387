import React, { useState, useEffect, useCallback } from "react";
import './index.css'
import TpoNavbar from "../TpoNavbar";
import TpoSidebar from "../TpoSidebar";
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import { IoSchoolOutline, IoBookOutline, IoBookmarkOutline, IoBriefcaseOutline, IoLocationOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../../admin/AdminFailureView";

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const TpoManagePost = () => {

    const [tpoData, setTpoData] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingTpoprofile = useCallback(async (tpoId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}tpo/get_post/${tpoId}/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedData = [...results].sort((a, b) => b.id - a.id)
            setTpoData(reversedData)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [])

    useEffect(() => {
        const tpo_login_token = Cookies.get('tpo_login_token')
        if (tpo_login_token) {
            const TpoToken = JSON.parse(tpo_login_token)
            gettingTpoprofile(TpoToken.id)
        }
    }, [gettingTpoprofile])

    const tpoDeleteBtn = async (deleteId) => {
        const url = `${Url}delete_tpo_post/${deleteId}/`
        const option = {
            method: 'DELETE',
        }
        const response = await fetch(url, option)
        if (response.ok === true) {
            const updatedData = tpoData?.filter((each) => each.id !== deleteId)
            setTpoData(updatedData)
        }
        else if (response.ok === false) {
            alert("Job Post Delete Fail!")
        }
    }

    const successView = () => {
        return (
            <>
                {tpoData?.map((each) => {
                    return (
                        <div key={each.id} className="col-lg-6 p-1">
                            <div className="bg-white  p-3 tpo-manage-sub-card">
                                <p className="hr-alljobs-items-para1 my-1 ">  <IoSchoolOutline size={19} /> <span className=" px-1"> {each.collegeName} </span></p>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookOutline size={19} /> <span className=" px-1"> {each.branch} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookmarkOutline size={19} /> <span className=" px-1"> {each.qualification} </span></p>
                                </div>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBriefcaseOutline size={19} /> <span className=" px-1"> {each.jobType} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <FiUsers size={19} /> <span className=" px-1"> {each.noOfStudent} </span></p>
                                </div>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-11">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.collegeAddress} </span></p>
                                    <MdDelete onClick={() => { window.confirm('Are you sure you want to delete?',) && tpoDeleteBtn(each.id) }} style={{ cursor: 'pointer' }} color="red" size={20} className="col-1 mt-2" />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    const rednerApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <TpoNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <TpoSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-4 p-1 py-4">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>All Posts</b></h4>
                    <div className="tpo-manage-sub-container p-3 my-md-3 d-flex row">
                        {rednerApiStatus()}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default TpoManagePost