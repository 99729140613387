import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const TpoProtectedRoute = (props) => {
    const { Component } = props
    const navigate = useNavigate()

    useEffect(() => {
        const tpo_login_token = Cookies.get('tpo_login_token')
        if (!tpo_login_token) {
            navigate('/tpo/login')
        }
    }, [navigate])

    return (
        <>
            <Component />
        </>
    )
}
export default TpoProtectedRoute