// Navbar
import hr_logo from '../Images/images/hr_logo.png'
import student_logo from '../Images/images/student_logo.png'
import tpo_logo from '../Images/images/tpo_logo.png'
import NHRC_LOGO from '../Images/images/NHRC_LOGO.png'
// Home
import img1 from '../Images/images/chapers_images/img1.png'
import img2 from '../Images/images/chapers_images/img2.png'
import img3 from '../Images/images/chapers_images/img3.png'
import img4 from '../Images/images/chapers_images/img4.png'
import img5 from '../Images/images/chapers_images/img5.png'
import img6 from '../Images/images/chapers_images/img6.png'
import img7 from '../Images/images/chapers_images/img7.png'
import img8 from '../Images/images/chapers_images/img8.png'
import img9 from '../Images/images/chapers_images/img9.png'
import img10 from '../Images/images/chapers_images/img10.png'
import img11 from '../Images/images/chapers_images/img11.png'
import img12 from '../Images/images/chapers_images/img12.png'

import home_video from '../Images/images/home_video.mp4'
import four_handshakes from '../Images/images/four_handshakes.jpg'
import mike from '../Images/images/mike.jpg'
import man_with_laptop from '../Images/images/man_with_laptop.jpg'
import two_handshakes from '../Images/images/two_handshakes.jpg'
import adp from '../Images/images/adp.png'
import axis from '../Images/images/axis.png'
import deloitte from '../Images/images/deloitte.png'
import home_event_img from '../Images/images/home_event_img.jpg'
import cloud from '../Images/images/cloud.jpg'
import happy_hr from '../Images/images/happy_hr.png'
import podcast_dummy from '../Images/images/podcast_dummy.jpg'
// Adimn Sidebar
import checkCorrect from '../Images/images/checkCorrect.gif'
// About Us 
import not_found from '../Images/images/not_found.jpg'
// form failure 
import something_went_wrong from '../Images/images/something_went_wrong.png'
//About us 
import about_us from '../Images/images/about_us.jpg'
import about_us_background from '../Images/images/about_us_background.jpg'
// Contact us 
import contact_img from '../Images/images/contact_img.jpg'
//Admin
import admin_profile from '../Images/images/admin_profile.png'
//hr-login
import hr_login_img from '../Images/images/Hr_Component/hr_login_img.png'
// import hr_login_bac from '../Images/images/Hr_Component/hr_login_bac.jpg'
import hr_view from '../Images/images/Hr_Component/hr_view.png'
//ysk
import ysk from '../Images/images/ysk.png'
import filterGIf from '../Images/images/filterGIf.gif'
//student
import student_bac1 from '../Images/images/Student_Component/student_bac1.png'
import student_bac2 from '../Images/images/Student_Component/student_bac2.png'
import student_about from '../Images/images/Student_Component/student_about.png'
import jobs1 from '../Images/images/Student_Component/jobs1.png'
import jobs2 from '../Images/images/Student_Component/jobs2.png'
import jobs3 from '../Images/images/Student_Component/jobs3.png'
import jobs4 from '../Images/images/Student_Component/jobs4.png'
import jobs5 from '../Images/images/Student_Component/jobs5.png'
import jobs6 from '../Images/images/Student_Component/jobs6.png'
import jobs7 from '../Images/images/Student_Component/jobs7.png'
import jobs8 from '../Images/images/Student_Component/jobs8.png'
import jobs9 from '../Images/images/Student_Component/jobs9.png'
import jobs10 from '../Images/images/Student_Component/jobs10.png'
import jobs11 from '../Images/images/Student_Component/jobs11.png'
import jobs12 from '../Images/images/Student_Component/jobs12.png'
import million_img from '../Images/images/Student_Component/million_img.jpg'
import student_how_applybg from '../Images/images/Student_Component/student_how_applybg.jpg'


const Images = {
    // Navbar
    hr_logo,
    student_logo,
    tpo_logo,
    NHRC_LOGO,
    // Home
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    home_video,
    four_handshakes,
    mike,
    man_with_laptop,
    two_handshakes,
    adp,
    axis,
    deloitte,
    home_event_img,
    cloud,
    happy_hr,
    podcast_dummy,
    // Adimn sidebar
    checkCorrect,
    // About us 
    not_found,
    //form failure 
    something_went_wrong,
    //About us 
    about_us,
    about_us_background,
    // Contact US
    contact_img,
    //Admin
    admin_profile,
    //hr-login
    hr_login_img,
    // hr_login_bac,
    hr_view,
    //ysk
    ysk,
    filterGIf,
    //student
    student_bac1,
    student_bac2,
    student_about,
    jobs1,
    jobs2,
    jobs3,
    jobs4,
    jobs5,
    jobs6,
    jobs7,
    jobs8,
    jobs9,
    jobs10,
    jobs11,
    jobs12,
    million_img,
    student_how_applybg,


}
export default Images
