import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const HrProtectedRoute = (props) => {
    const { Component } = props
    const navigate = useNavigate()

    useEffect(() => {
        const hr_login_token = Cookies.get('hr_login_token')
        if (!hr_login_token) {
            navigate('/hr/login')
        }
    }, [navigate])

    return (
        <>
            <Component />
        </>
    )
}
export default HrProtectedRoute