import React, { useState, useEffect } from "react";
import JobDetails from "./JobDetails";
import CompanyDetails from "./CompanyDetails";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { Stepper } from 'react-form-stepper';
import Modal from 'react-bootstrap/Modal';
import Images from "../../Images";
import HrNavbar from "../../HrComponent/HrNavbar";
import HrSidebar from "../../HrComponent/HrSidebar";
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import FormLoader from "../../Loaders/FormLoader";


const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const HrJobPostMain = () => {

    const navigate = useNavigate()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [showCorrect, setShowCorrect] = useState(false);
    const showingPopup = () => {
        return (
            <Modal show={showCorrect} className=' ' centered >
                <Modal.Body className='text-center d-flex flex-column justify-content-center align-items-center model-height'>

                    {formRenderview()}

                </Modal.Body>
            </Modal>
        )
    }

    const [formStep, setFormStep] = useState(0)
    const [jobDetailsData, setJobDetailsData] = useState()
    const [companyDetailsData, setCompanyDetailsData] = useState()

    const [formValid, setformValid] = useState({
        isJobDetailsValid: false,
        isCompanyDetailsValid: false
    })

    const addFormData = (type, data) => {
        if (type === 'jobDetails') {
            setJobDetailsData(data)
        }
        else if (type === 'companyDetails') {
            setCompanyDetailsData(data)
        }
    }

    const validatingFormValid = (type, isValid) => {
        if (type === 'jobDetails' && isValid !== formValid.isJobDetailsValid) {
            setformValid({ ...formValid, isJobDetailsValid: isValid })
        }
        else if (type === 'companyDetails' && isValid !== formValid.isCompanyDetailsValid) {
            setformValid({ ...formValid, isCompanyDetailsValid: isValid })
        }
    }

    const onClickNextStep = () => {
        if (formStep === 0) {
            document.getElementById("jobDetails-details-form")?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
            if (formValid.isJobDetailsValid) {
                setFormStep(cur => cur + 1)
                toast.success('Your Redirected to Next Step !...')
            }
        }
        else {
            document.getElementById("companyDetails-details-form")?.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }
    }

    const successView = () => {
        return (
            <>
                <img className='w-25' src={Images.checkCorrect} alt='Correct' />
                <h6 className='correct-text'>You have Successfully Registered </h6>
                <h6 className="correct-text m-0">Please Wait for Admin Approval</h6>
            </>
        )
    }

    const failureView = () => {
        return (
            <>
                <img src={Images.something_went_wrong} className="w-100 abc" alt="img" />
                <button type="button" onClick={() => { navigate('/') }} className="btn btn-warning btn-sm">Retry</button>
            </>
        )
    }

    const formRenderview = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <FormLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <>{failureView()}</>
            default:
                return null
        }
    }



    useEffect(() => {

        const fetchingHrRegister = async (finalData, hrId) => {
            const formData = new FormData()
            formData.append('hr', parseInt(hrId))
            formData.append('jobTitle', finalData.jobTitle)
            formData.append('jobDescription', finalData.jobDescription)
            formData.append('department', finalData.department)
            formData.append('state', finalData.state)
            formData.append('district', finalData.district)
            formData.append('address', finalData.address)
            formData.append('employmentType', finalData.employmentType)
            formData.append('shiftType', finalData.shiftType)
            formData.append('salaryFrom', finalData.salaryFrom)
            formData.append('salaryEnd', finalData.salaryEnd)
            formData.append('experienceLevel', finalData.experienceLevel)
            formData.append('educationLevel', finalData.educationLevel)
            formData.append('qualifications', finalData.qualifications)
            formData.append('contactPerson', finalData.contactPerson)
            formData.append('contactEmail', finalData.contactEmail)
            formData.append('contactPhone', finalData.contactPhone)
            formData.append('applicationDeadline', finalData.applicationDeadline)
            formData.append('jobResponsibilities', finalData.jobResponsibilities)
            formData.append('skillsRequired', finalData.skillsRequired)
            formData.append('companyName', finalData.companyName)
            formData.append('companyLevel', finalData.companyLevel)
            formData.append('foundedIn', finalData.foundedIn)
            formData.append('companylogo', finalData.companylogo[0])
            formData.append('companyDescription', finalData.companyDescription)
            formData.append('howtoApply', finalData.howtoApply)
            formData.append('websiteUrl', finalData.websiteUrl)


            setApiStatus(currentApiStatuses.inProgress)

            const url = `${Url}hr/post/${hrId}/`
            const options = {
                method: 'POST',
                body: formData
            }
            const response = await fetch(url, options)

            if (response.ok === true) {
                setApiStatus(currentApiStatuses.success)
                setTimeout(() => {
                    navigate('/hr/dashboard')
                }, 3000)
            }
            else {
                setApiStatus(currentApiStatuses.failure)
            }

        }

        if (
            jobDetailsData &&
            companyDetailsData &&
            Object.keys(jobDetailsData).length !== 0 &&
            Object.keys(companyDetailsData).length !== 0 &&
            formValid.isJobDetailsValid &&
            formValid.isCompanyDetailsValid
        ) {
            const finalData = {
                ...jobDetailsData,
                ...companyDetailsData,
            };
            // Api call
            setShowCorrect(true);

            const hr_login_token = Cookies.get('hr_login_token')
            const Token = JSON.parse(hr_login_token)

            fetchingHrRegister(finalData, Token.id)
        }
    }, [jobDetailsData, companyDetailsData, formValid.isJobDetailsValid, formValid.isCompanyDetailsValid, navigate]);


    return (
        <>
            <div className="hr-all-forms-container  ">
                <div className="vh-100 w-100">
                    <HrNavbar />
                    <div className="d-flex hr-dashboard-content-container">
                        <div className="col-lg-2 d-none d-lg-block">
                            <HrSidebar />
                        </div>
                        <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-4 p-1 py-4">
                            <Stepper
                                steps={[{ label: '' }, { label: '' }]}
                                activeStep={formStep}
                                className="p-1 mt-2 text-white"

                            />
                            {formStep === 0 && (
                                <section className={formStep === 0 ? 'block' : 'd-none'}>
                                    <JobDetails
                                        addFormData={addFormData}
                                        validatingFormValid={validatingFormValid}
                                    />
                                </section>
                            )}
                            {formStep === 1 && (
                                <section className={formStep === 1 ? 'block' : 'd-none'}>
                                    <CompanyDetails
                                        addFormData={addFormData}
                                        validatingFormValid={validatingFormValid}
                                    />
                                </section>
                            )}
                            <button onClick={() => { setFormStep(cur => cur - 1) }} className={`nextBtn ${formStep === 0 && 'd-none'}`}>
                                <span>Prev</span>
                            </button>
                            <button onClick={onClickNextStep} className="nextBtn">
                                <span>{formStep === 0 ? 'Next' : 'Register'}</span>
                            </button>
                            <Toaster
                                position="top-right"
                                reverseOrder={false}
                            />
                        </div>
                        {showingPopup()}
                    </div>
                </div>
            </div >
        </>
    )
}
export default HrJobPostMain








