import React, { useEffect, useState } from "react";
import './index.css'
import StudentNavbar from "../StudentNavbar";
import StudentSidebar from "../StudentSidebar";
import Cookies from "js-cookie";
import States from '../../pages/States.json'
import Url from '../../Url/Url'
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'
import FormLoader from "../../Loaders/FormLoader";
import Images from "../../Images";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';


const genderData = [
    { id: 1, value: 'male', label: 'Male' },
    { id: 2, value: 'feMale', label: 'FeMale' },
    { id: 3, value: 'others', label: 'Others' },
]



const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}


const StudentProfileView = () => {

    const [studentData, setStudentData] = useState()
    const [updatedData, setUpdatedData] = useState({})
    const [studentProfileId, setStudentProfileId] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [apiStatusUpdate, setApiStatusUpdate] = useState(currentApiStatuses.initial)
    const [showCorrect, setShowCorrect] = useState(false);
    const navigate = useNavigate()


    const gettingStudentprofile = async (studentId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}student/${studentId}/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setStudentData(results)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }

    useEffect(() => {
        const studentLoginToken = Cookies.get('student_login_token')
        if (studentLoginToken) {
            const StudentToken = JSON.parse(studentLoginToken)
            gettingStudentprofile(StudentToken.id)
            setStudentProfileId(StudentToken.id)
        }
    }, [])


    const profileUpdateInputFields = (event) => {
        const { name, value } = event.target
        setUpdatedData({ ...updatedData, [name]: value })
    }


    const successView = () => {
        return (
            <div className="">
                <h4 style={{ letterSpacing: '1px' }} className="p-0 m-2"><b>Profile</b></h4>
                <div className="hr-profile-view-inputs-container p-md-4 p-3 my-3">
                    <h6><b>My Profile</b></h6>
                    {studentData && (
                        <form className="my-4" onSubmit={onSubmitedUpdatedData}>
                            <img src={studentData.profilePic} alt="Profile Pic" className="hr-profile-view-pic" />
                            <div className="d-flex row">
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Full Name</label>
                                    <input type="text" name="fullName" placeholder={studentData.fullName} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Gender</label>
                                    <select name="gender" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={studentData.gender}>{studentData.gender}</option>
                                        {genderData?.map((each) => (
                                            each?.value !== studentData?.gender && (
                                                <option key={each.value} value={each.value}>
                                                    {each.label}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Date of Birth</label>
                                    <input type="date" name="dateOfBirth" placeholder={studentData.dateOfBirth} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">State</label>
                                    <select name="state" onChange={profileUpdateInputFields} className="hr-profile-view-input w-100">
                                        <option value={studentData.state}>{studentData.state}</option>
                                        {States.states?.map((each) => (
                                            each?.value !== studentData?.state && (
                                                <option key={each.value} value={each.value}>
                                                    {each.state}
                                                </option>
                                            )
                                        ))}
                                    </select>
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">District</label>
                                    <input type="text" name="district" placeholder={studentData.district} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Pincode</label>
                                    <input type="number" name="pincode" placeholder={studentData.pincode} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Email</label>
                                    <input disabled type="email" name="personalMail" placeholder={studentData.personalMail} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Mobile Number</label>
                                    <input type="text" name="mobileNumber" placeholder={studentData.mobileNumber} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">University Name</label>
                                    <input type="text" name="universityName" placeholder={studentData.universityName} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">College Name</label>
                                    <input type="text" name="collegeName" placeholder={studentData.collegeName} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Qualification</label>
                                    <input type="text" name="qualification" placeholder={studentData.qualification} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Department</label>
                                    <input type="text" name="department" placeholder={studentData.department} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Year Of Passout</label>
                                    <input type="number" name="passOut" placeholder={studentData.passOut} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                                <div className="hr-profile-view-input-container col-lg-6 my-2">
                                    <label className="hr-profile-view-label my-1">Location</label>
                                    <input type="text" name="location" placeholder={studentData.location} onChange={profileUpdateInputFields} className="hr-profile-view-input w-100" />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-sm mt-3 btn-outline-primary" >Submit</button>
                        </form>
                    )}

                </div>
            </div>
        )
    }

    const renderApiStatusView = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    const successViewUpdate = () => {
        return (
            <>
                <img className='w-25' src={Images.checkCorrect} alt='Correct' />
                <h6 className='correct-text'>You have Successfully Updated </h6>
                <button className="btn btn-success btn-sm my-3" onClick={() => navigate('/student/dashboard')} >Home</button>
            </>
        )
    }

    const failureViewUpdate = () => {
        return (
            <>
                <img src={Images.something_went_wrong} className="w-100 abc" alt="img" />
                <button type="button" onClick={() => { navigate('/student/dashboard') }} className="btn btn-warning btn-sm">Retry</button>
            </>
        )
    }

    const formRenderview = () => {
        switch (apiStatusUpdate) {
            case currentApiStatuses.inProgress:
                return <FormLoader />
            case currentApiStatuses.success:
                return <>{successViewUpdate()}</>
            case currentApiStatuses.failure:
                return <>{failureViewUpdate()}</>
            default:
                return null
        }
    }

    const showingPopup = () => {
        return (
            <Modal show={showCorrect} className=' ' centered >
                <Modal.Body className='text-center d-flex flex-column justify-content-center align-items-center model-height'>
                    {formRenderview()}
                </Modal.Body>
            </Modal>
        )
    }

    const onSubmitedUpdatedData = async (event) => {
        event.preventDefault()
        if (Object.keys(updatedData).length !== 0) {
            setShowCorrect(true)
            setApiStatusUpdate(currentApiStatuses.inProgress)
            const url = `${Url}student/update/${studentProfileId}/`
            const options = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData)
            }
            const response = await fetch(url, options)

            if (response.ok === true) {
                setApiStatusUpdate(currentApiStatuses.success)
            }
            else {
                setApiStatusUpdate(currentApiStatuses.failure)
            }
        }
        else {
            alert('No Changes Made')
        }

    }

    return (
        <div className="vh-100 w-100">
            <StudentNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <StudentSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-4 p-1 py-4">
                    {renderApiStatusView()}
                    {showingPopup()}
                </div>
            </div>
        </div >
    )
}
export default StudentProfileView