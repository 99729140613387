import React, { useState, useEffect } from "react";
import './index.css'
import StudentSidebar from '../StudentSidebar'
import StudentNavbar from '../StudentNavbar'
import { useNavigate } from "react-router-dom"
import { BsCurrencyRupee } from "react-icons/bs";
import { IoLocationOutline, IoDocumentTextOutline, IoSearch } from "react-icons/io5";
import { BiBookmark } from "react-icons/bi";
import { TbBriefcase2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import Url from '../../Url/Url'
import DOMPurify from "dompurify";
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Images from "../../Images";

const employmentType = [
    { id: 0, text: 'All', value: 'All' },
    { id: 1, text: 'Fulltime', value: 'Fulltime' },
    { id: 2, text: 'Part-Time', value: 'PartTime' },
    { id: 3, text: 'Casual', value: 'Casual' },
    { id: 4, text: 'Fixed-term contract', value: 'Fixedterm' },
    { id: 5, text: 'Apprenticeship', value: 'Apprenticeship' },
    { id: 6, text: 'Traineeship', value: 'Traineeship' },
    { id: 7, text: 'Internship', value: 'Internship' },
]

const previousDays = [
    {
        id: 0,
        value: 'All',
        text: 'All'
    },
    {
        id: 1,
        value: 0,
        text: 'Today'
    },
    {
        id: 2,
        value: 1,
        text: 'Yesterday'
    },
    {
        id: 3,
        value: 2,
        text: 'Last 2 Days'
    },
    {
        id: 4,
        value: 3,
        text: 'Last 3 Days'
    },
    {
        id: 5,
        value: 7,
        text: 'Last 7 Days'
    },
    {
        id: 6,
        value: 30,
        text: 'Last 30 Days'
    },
    {
        id: 7,
        value: 60,
        text: 'Last 60 Days'
    },
]


const experienceOptions = [
    { value: 'All', label: 'Fresher' },
    { value: '1', label: '0 to 1' },
    { value: '2', label: '1 to 2' },
    { value: '3', label: '2 to 3' },
    { value: '4', label: '3 to 4' },
    { value: '5', label: '4 to 5' },
    { value: '6', label: '5 to 6' },
    { value: '7', label: '6 to 7' },
    { value: '8', label: '7 to 8' },
    { value: '9', label: '8 to 9' },
    { value: '10', label: '9 to 10' },
    { value: '11', label: '10 to 11' },
    { value: '12', label: '11 to 12' },
    { value: '13', label: '12 to 13' },
    { value: '14', label: '13 to 14' },
    { value: '15', label: '14 to 15' },
    { value: '20', label: '15 to 20' },
    { value: '30', label: '20 to 30' },
];

const incomeOptions = [
    { value: '50000', label: '50,000' },
    { value: '100000', label: '100,000' },
    { value: '200000', label: '200,000' },
    { value: '300000', label: '300,000' },
    { value: '400000', label: '400,000' },
    { value: '500000', label: '500,000' },
    { value: '600000', label: '600,000' },
    { value: '700000', label: '700,000' },
    { value: '800000', label: '800,000' },
    { value: '900000', label: '900,000' },
    { value: '1000000', label: '1,000,000' },
    { value: '2000000', label: '2,000,000' },
    { value: '5000000', label: '5,000,000' },
];


const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const StudentJobs = () => {

    const [jobTypeValue, setJobTypeValue] = useState({
        jobType: '',
        days: '',
        experience: '',
        salary: '',
        search: ''

    })
    const [approvedJobs, setApprovedJobs] = useState()
    const [filteredJobs, setFilteredJobs] = useState([]);
    const navigate = useNavigate()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [date, setDate] = useState()

    const gettingPostDays = (jobPostDate) => {
        const formattedDate = jobPostDate && jobPostDate.slice(8, 10) + '-' + jobPostDate.slice(5, 7) + '-' + jobPostDate.slice(0, 4);
        return formattedDate
    };

    const gettingActiveorNot = (deadline) => {
        const today = new Date().toJSON().slice(0, 10)
        const results = today > deadline
        return (
            <div className={`${results ? 'hr-jobpost-expired-text-div' : 'hr-jobpost-active-text-div'}`}>
                <p className=" my-0 ">
                    {results ? 'Expired' : 'Active'}
                </p>
            </div>
        )
    }

    useEffect(() => {
        const today = new Date().toJSON().slice(0, 10)
        setDate(today)
    }, [setDate])

    const onJobTypeChange = (event) => {
        const queryParameters = new URLSearchParams(window.location.search)
        const { name, value } = event.target;

        setJobTypeValue({ ...jobTypeValue, [name]: value });

        if (value !== '') {
            queryParameters.set(name, value);
        } else {
            queryParameters.delete(name);
        }
        navigate({ search: queryParameters.toString() });
    }


    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const jobTypeQuery = queryParameters.get('jobType') || '';
        const daysQuery = queryParameters.get('days') || '';
        const experienceQuery = queryParameters.get('experience') || '';
        const salaryQuery = queryParameters.get('salary') || '';
        const searchQuery = queryParameters.get('search') || '';

        setJobTypeValue(
            {
                ...jobTypeValue, jobType: jobTypeQuery,
                days: daysQuery,
                experience: experienceQuery,
                salary: salaryQuery,
                search: searchQuery
            }
        );

    }, []);

    const gettingAllJobsStudent = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}hr/all_approved_job_posts/`
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedData = [...results.approved_job_postings].sort((a, b) => b.id - a.id)
            setApprovedJobs(reversedData)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [])

    useEffect(() => {
        gettingAllJobsStudent()
    }, [gettingAllJobsStudent])


    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }

    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }



    useEffect(() => {
        if (!approvedJobs) {
            return;
        }

        let combinedFilter = approvedJobs;

        if (jobTypeValue.jobType && jobTypeValue.jobType !== 'All') {
            combinedFilter = combinedFilter.filter(each => each.employmentType === jobTypeValue.jobType);
        }

        if (jobTypeValue.experience && jobTypeValue.experience !== 'All') {
            combinedFilter = combinedFilter.filter(each => each.experienceLevel === String(jobTypeValue.experience));
        }

        if (jobTypeValue.salary && jobTypeValue.salary !== '50000') {
            combinedFilter = combinedFilter.filter(each => parseInt(each.salaryFrom) >= parseInt(jobTypeValue.salary));
        }

        if (jobTypeValue.days && jobTypeValue.days !== 'All') {
            const currentDate = new Date();
            const thresholdDate = new Date(currentDate);
            thresholdDate.setDate(currentDate.getDate() - parseInt(jobTypeValue.days));

            combinedFilter = combinedFilter.filter(job => {
                const jobDate = new Date(job.approved_date);
                const timeDifference = currentDate - jobDate;
                const daysDifference = timeDifference / (1000 * 3600 * 24);

                return daysDifference <= parseInt(jobTypeValue.days);
            });
        }


        if (jobTypeValue.search && jobTypeValue.search !== '') {
            combinedFilter = combinedFilter.filter(each =>
                each.jobTitle?.toLowerCase().includes(jobTypeValue?.search?.toLowerCase()) ||
                each.companyName?.toLowerCase().includes(jobTypeValue?.search?.toLowerCase()) ||
                each.state?.toLowerCase().includes(jobTypeValue?.search?.toLowerCase()) ||
                each.district?.toLowerCase().includes(jobTypeValue?.search?.toLowerCase()) ||
                each.qualifications?.toLowerCase().includes(jobTypeValue?.search?.toLowerCase())
            );
        }

        setFilteredJobs(combinedFilter);
    }, [jobTypeValue, approvedJobs]);

    const successView = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center ">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 m-2 mt-3"><b>Jobs!</b></h4>
                    <div className="admin-hr-search-input-container Student-job-search-input d-flex">
                        <input type="search" name="search" value={jobTypeValue.search} onChange={onJobTypeChange} className="admin-hr-search-input w-100 h-100" placeholder="Search.." alt="img" />
                        <IoSearch size={25} color='gray' className="h-100 " />
                    </div>
                </div>
                <div className="text-end d-md-none">
                    <div>
                        <img src={Images.filterGIf} style={{ cursor: 'pointer' }} className="mx-2" onClick={handleShow} alt="Filter" width={35} />
                    </div>
                    <Offcanvas show={show} onHide={handleClose} className='w-75 px-2'>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title></Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div>
                                <h6 className=""><b>Job Type</b></h6>
                                {employmentType?.map((each) => {
                                    return (
                                        <div key={each.id} className="form-check form-switch my-2 ">
                                            <input style={{ boxShadow: 'none' }} className="form-check-input" checked={jobTypeValue.jobType === `${each.value}`} onChange={onJobTypeChange} value={each.value} type="checkbox" name='jobType' role="switch" />
                                            <label style={{ fontSize: '14px' }} className="form-check-label" htmlFor='jobType'>{each.text}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="my-4">
                                <h6 className="mt-2"><b>Salary</b></h6>
                                <input type="range" value={jobTypeValue.salary} list="salary" className="form-range w-100" style={{ boxShadow: 'none' }} onChange={onJobTypeChange} name="salary" min="50000" max="5000000" />
                                <datalist id="salary" >
                                    {incomeOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </datalist>
                                {jobTypeValue.salary !== '' && (
                                    <p>{jobTypeValue.salary} /-</p>
                                )}
                            </div>
                            <div className="my-4">
                                <h6 className="mt-2"><b>Date Posted</b></h6>
                                {previousDays?.map((each) => {
                                    return (
                                        <div key={each.id} className="form-check form-switch my-2 ">
                                            <input style={{ boxShadow: 'none' }} className="form-check-input" checked={jobTypeValue.days === `${each.value}`} onChange={onJobTypeChange} value={each.value} type="checkbox" name='days' role="switch" />
                                            <label style={{ fontSize: '14px' }} className="form-check-label" htmlFor='days'>{each.text}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="my-4">
                                <h6 className="mt-2"><b>Experience Level</b></h6>
                                <select name="experience" value={jobTypeValue.experience} onChange={onJobTypeChange} style={{ boxShadow: 'none', fontSize: '14px' }} className="form-select my-2">
                                    {experienceOptions?.map((each) => {
                                        return (
                                            <option key={each.value} value={each.value}>{each.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div>
                <div className="p-2 d-flex ">
                    <div className="col-md-3 p-3 student-jobs-filter-container d-none d-md-block">
                        <div>
                            <h6 className=""><b>Job Type</b></h6>
                            {employmentType?.map((each) => {
                                return (
                                    <div key={each.id} className="form-check form-switch my-2 ">
                                        <input style={{ boxShadow: 'none' }} className="form-check-input" checked={jobTypeValue.jobType === `${each.value}`} onChange={onJobTypeChange} value={each.value} type="checkbox" name='jobType' role="switch" />
                                        <label style={{ fontSize: '14px' }} className="form-check-label" htmlFor='jobType'>{each.text}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="my-4">
                            <h6 className="mt-2"><b>Salary</b></h6>
                            <input type="range" value={jobTypeValue.salary} list="salary" className="form-range w-100" style={{ boxShadow: 'none' }} onChange={onJobTypeChange} name="salary" min="50000" max="5000000" />
                            <datalist id="salary" >
                                {incomeOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </datalist>
                            {jobTypeValue.salary !== '' && (
                                <p>{jobTypeValue.salary} /-</p>
                            )}
                        </div>
                        <div className="my-4">
                            <h6 className="mt-2"><b>Date Posted</b></h6>
                            {previousDays?.map((each) => {
                                return (
                                    <div key={each.id} className="form-check form-switch my-2 ">
                                        <input style={{ boxShadow: 'none' }} className="form-check-input" checked={jobTypeValue.days === `${each.value}`} onChange={onJobTypeChange} value={each.value} type="checkbox" name='days' role="switch" />
                                        <label style={{ fontSize: '14px' }} className="form-check-label" htmlFor='days'>{each.text}</label>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="my-4">
                            <h6 className="mt-2"><b>Experience Level</b></h6>
                            <select name="experience" value={jobTypeValue.experience} onChange={onJobTypeChange} style={{ boxShadow: 'none', fontSize: '14px' }} className="form-select my-2">
                                {experienceOptions?.map((each) => {
                                    return (
                                        <option key={each.value} value={each.value}>{each.label}</option>
                                    )
                                })}
                            </select>
                        </div>

                    </div>
                    <div className="col-md-9 col-12 px-md-2">
                        <div className="px-md-2  row">
                            {filteredJobs?.filter((exDate) => exDate.applicationDeadline > date).map((each, index) => {
                                return (
                                    <Link to={`/student/job/details/${each.id}`} key={index} style={{ color: '#474d6a', textDecoration: 'none' }} className="mb-3 col-12 ">
                                        <div className="hr-alljobs-items-container p-3 ">
                                            <div className="d-flex justify-content-between ">
                                                <div>
                                                    <h6 className="text-dark hr-alljobs-items-para1"><b>{each.jobTitle}</b></h6>
                                                    <h6 className="text-dark hr-alljobs-items-para1"><b>{each.companyName}</b></h6>
                                                </div>
                                                <div className="hr-alljobs-items-img-container">
                                                    <img src={each.companylogo} alt="img" className="hr-alljobs-items-img" />
                                                </div>
                                            </div>
                                            <div className="d-flex row my-2">
                                                <p className="hr-alljobs-items-para my-1 col-5 col-md-3">  <TbBriefcase2 size={19} /> <span className=" px-1"> {each.experienceLevel} Yrs </span></p>
                                                <p className="hr-alljobs-items-para my-1 px-2  col-7 col-md-4">  <BsCurrencyRupee size={19} /> <span className=" px-1"> {each.salaryFrom} - {each.salaryEnd} PA </span></p>
                                                <p className="hr-alljobs-items-para1 my-1 px-2 col-12 col-md-5">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.state}, {each.district}</span></p>
                                                <div className="d-flex my-1">
                                                    <div>
                                                        <IoDocumentTextOutline size={19} />
                                                    </div>
                                                    {getJobDescriptionData(each.jobDescription)}
                                                </div>
                                                <div className="d-flex my-1">
                                                    <div>
                                                        <BiBookmark size={19} />
                                                    </div>
                                                    {getSkillsRequiredData(each.skillsRequired)}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between hr-alljobs-items-days">
                                                <p className="text-secondary my-0 px-1">
                                                    {gettingPostDays(each.approved_date)}
                                                </p>
                                                <>
                                                    {gettingActiveorNot(each.applicationDeadline)}
                                                </>

                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </>
        )
    }

    const rednerApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }


    return (
        <div className="vh-100 w-100">
            <StudentNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <StudentSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-3 p-1 py-4">
                    {rednerApiStatus()}
                </div>
            </div>
        </div >
    )
}
export default StudentJobs




