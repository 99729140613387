import React, { useEffect, useState, useCallback } from "react";
import './index.css'
import HrNavbar from '../HrNavbar'
import HrSidebar from '../HrSidebar'
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Url from '../../Url/Url'
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const BlockProfile = () => {
    const navigate = useNavigate()
    const [profileData, seteProfileData] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingBlackProfile = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}blockprofiles/ `
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()

        if (response.ok === true) {
            const revesedData = [...results].sort((a, b) => b.id - a.id)
            seteProfileData(revesedData)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [seteProfileData])

    useEffect(() => {
        gettingBlackProfile()
    }, [gettingBlackProfile])

    const profileDeleteBtn = async (deleteId) => {
        const url = `${Url}blockprofile/delete/${deleteId}/`
        const options = {
            method: 'DELETE'
        }
        const response = await fetch(url, options)
        if (response.ok === true) {
            const newFilterdData = profileData.filter((each) => each.id !== deleteId)
            seteProfileData(newFilterdData)
        }
        else {
            alert('Delete Fail !')
        }
    }

    const successView = () => {
        return (
            <>
                <div className="d-flex justify-content-between align-items-center px-md-3 ">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>Block Profiles</b></h4>
                    <div>
                        <button type="button" className="btn btn-outline-primary btn-sm mx-2" onClick={() => { navigate('/blockprofile/create') }}>Post Profile</button>
                    </div>
                </div>
                <div className="p-2 d-lg-flex row">
                    {profileData?.map((each) => {
                        return (
                            <div key={each.id} className="col-12 p-2 py-md-2 px-md-3 ">
                                <div className="block-profile-card-container  p-3  d-md-flex ">
                                    <img src={each.profileImage} alt="img" loading="lazy" className="block-profile-card-img" />
                                    <div className="block-profile-detail-card-container px-md-3 px-1 py-md-1 py-3 ">
                                        <div className="d-flex justify-content-between ">
                                            <h5 className="block-profile-detail-card-heading"><b>{each.fullName}</b></h5>
                                            <MdDeleteForever size={30} onClick={() => { window.confirm('Are you sure you want to delete?',) && profileDeleteBtn(each.id) }} className="block-profile-detail-card-delete p-1" />

                                        </div>
                                        <div className="py-2 d-flex row">
                                            <p className="block-profile-detail-card-aadhar col-md-6 my-1">Date of Birth : <span className="block-profile-detail-card-dateofbirth-span">{each.date}</span></p>
                                            <p className="block-profile-detail-card-aadhar col-md-6 my-1">Aadhar Number : <span className="block-profile-detail-card-aadhar-span">{each.aadharCardNumber}</span></p>
                                            <p className="block-profile-detail-card-aadhar col-md-6 my-1">Pan Number : <span className="block-profile-detail-card-pan-span">{each.panNumber}</span></p>
                                            <p className="block-profile-detail-card-aadhar col-md-6 my-1">UIN Number : <span className="block-profile-detail-card-uin-span">{each.uniNumber}</span></p>
                                        </div>
                                        <p className="block-profile-detail-card-aadhar col-12 my-1">{each.remarks}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </>
        )
    }

    const renderApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-lg-3 p-1 py-4">
                    <div className="">
                        {renderApiStatus()}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlockProfile