import React, { useState, useEffect } from "react";
import './index.css'
import HrProfile from "../HrProfile";
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'reactjs-popup/dist/index.css';
import { HiOutlineUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { IoHomeOutline, IoFileTrayOutline, IoSendOutline, IoBriefcaseOutline, IoBookmarkOutline, IoBanOutline } from "react-icons/io5";
import Images from "../../Images";

const HrNavbar = () => {

    const [offcanvasShow, setoffcanvasShow] = useState(false);
    const [sidebarLocation, setSidebarLocation] = useState()

    useEffect(() => {
        setSidebarLocation(window.location.pathname)
    }, [])


    const handleClose = () => setoffcanvasShow(false);
    const handleShow = () => setoffcanvasShow(true);

    return (
        <div>
            <nav className="hr-nav-lg-container fixed-top d-flex justify-content-between align-items-center">
                <img className="w-100 hr-nav-logo d-none d-lg-block" src={Images.NHRC_LOGO} alt="Logo" />
                <img className="w-100 hr-nav-logo d-lg-none" onClick={handleShow} src={Images.NHRC_LOGO} alt="Logo" />
                <HrProfile />
            </nav>
            <Offcanvas show={offcanvasShow} className='admin-mobile-offcanvas-container w-75 d-lg-none' onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div >
                        <Link to='/hr/dashboard' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/dashboard' ? 'active_path' : ''}`}>
                            <IoHomeOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">Dashboard</h6>
                        </Link>
                        <Link to='/hr/post/job' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/post/job' ? 'active_path' : ''}`}>
                            <IoSendOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">Post Job</h6>
                        </Link>
                        <Link to='/hr/all/jobs' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/all/jobs' ? 'active_path' : ''}`}>
                            <IoBriefcaseOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">Manage Jobs</h6>
                        </Link>
                        <Link to='/hr/all/applicants' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/all/applicants' ? 'active_path' : ''}`}>
                            <IoFileTrayOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">All Applicants</h6>
                        </Link>
                        <Link to='/hr/tpo/posts' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/tpo/posts' ? 'active_path' : ''}`}>
                            <IoBookmarkOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">TPO</h6>
                        </Link>
                        <Link to='/hr/profile' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/hr/profile' ? 'active_path' : ''}`}>
                            <HiOutlineUsers color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">Your Profile</h6>
                        </Link>
                        <Link to='/blockprofile' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/blockprofile' ? 'active_path' : ''}`}>
                            <IoBanOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                            <h6 className="hr-sidebar-items-pre m-0">Block Profile</h6>
                        </Link>
                    </div>

                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
export default HrNavbar


