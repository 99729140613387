import React from "react";
import './index.css'
import StudentSidebar from "../StudentSidebar";
import StudentNavbar from "../StudentNavbar";
import Images from "../../Images";

const popularJobs = [
    {
        image: Images.jobs1,
        job: 'Accounting / Finance',
        positions: '2 open positions'
    },
    {
        image: Images.jobs2,
        job: 'Marketing',
        positions: '28 open positions'
    },
    {
        image: Images.jobs3,
        job: 'Design',
        positions: '58 open positions'
    },
    {
        image: Images.jobs4,
        job: 'Development',
        positions: '58 open positions'
    },
    {
        image: Images.jobs5,
        job: 'Human Resources',
        positions: '32 open positions'
    },
    {
        image: Images.jobs6,
        job: 'Automotive Jobs',
        positions: '51 open positions'
    },
    {
        image: Images.jobs7,
        job: 'Customer Services',
        positions: '29 open positions'
    },
    {
        image: Images.jobs8,
        job: 'Health care',
        positions: '99 open positions'
    },
    {
        image: Images.jobs9,
        job: 'Project Manager',
        positions: '111 open positions'
    },
]


const StudentDashboard = () => {

    return (
        <div className="vh-100 w-100">
            <StudentNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <StudentSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-3 p-1 py-4">
                    <div className="student-dashboard-dreamjob-container position-relative p-2 mb-5">
                        <div className="d-flex student-dashboard-dreamjob-jobs-container position-absolute ">
                            <div className="col-lg-6 d-flex flex-column justify-content-end text-center">
                                <h1 className="student-dashboard-dreamjob-jobs-heading  ">JOBS</h1>
                            </div>
                            <div className="col-lg-6 d-none d-lg-block">
                                <img src={Images.student_bac1} loading="lazy" className="student-dashboard-dreamjob-jobs-img" alt="img" />
                            </div>
                        </div>
                        <div className="d-flex student-dashboard-dreamjob1-container position-absolute ">
                            <div className="col-lg-6 d-flex flex-column justify-content-center ">
                                <h5 className="student-dashboard-dreamjob1-para m-0">Easiest way to find a perfect job</h5>
                                <h1 className='my-4 find-your-heading' ><b>Find Your Next Dream Job</b></h1>
                                <div>
                                    <button type="button" className='btn btn-outline-primary '>LOOKING FOR A JOB</button>
                                    <button type="button" className='btn btn-outline-success mx-2 '>Find Talents</button>
                                </div>

                            </div>
                            <div className="col-lg-6 d-none d-lg-block d-lg-flex justify-content-center  align-items-center ">
                                <img src={Images.student_bac2} loading="lazy" className="student-dashboard-dreamjob-jobs-img1" alt="img" />
                            </div>
                        </div>
                    </div>
                    <div className="student-dashboard-talent-container p-2 py-5 d-lg-flex">
                        <div className="col-lg-6 px-md-2 ">
                            <img src={Images.student_about} className="student-dashboard-talent-img" alt="img" />
                        </div>
                        <div className="col-lg-6 px-md-4 px-1 mt-4">
                            <h2 className="student-dashboard-talent-heading mb-4 py-1">We Build Lasting Relationships Between Candidates & Businesses</h2>
                            <p className="student-dashboard-talent-para">The automated process starts as soon as your clothes go into the machine. The outcome is gleaming clothes. Placeholder text commonly used.</p>
                            <p className="student-dashboard-talent-para">Automated process starts as soon as your clothes go into the machine. The outcome is gleaming clothes. Placeholder text commonly used.</p>
                            <button type="button" className="btn btn-outline-primary">FIND TALENT</button>
                        </div>
                    </div>
                    <div className="p-2 bg-white py-5 student-dashboard-popular-container">
                        <div className="text-md-center">
                            <h4><b>Popular Job Categories</b></h4>
                            <p className="text-secondary py-2">2024 jobs live . 911 added today</p>
                        </div>
                        <div className="row p-md-3 p-2 ">
                            {popularJobs?.map((each, index) => {
                                return (
                                    <div key={index} className="col-lg-4 col-md-6 p-2" >
                                        <div className="popular-jobs-card-container d-flex p-3">
                                            <div className="col-lg-3 col-md-3 col-4">
                                                <img src={each.image} alt={each.job} className="w-100 popular-jobs-card-img p-2" />
                                            </div>
                                            <div className="  col-lg-9 col-md-9 col-8 d-flex flex-column justify-content-center px-3">
                                                <h6 >{each.job}</h6>
                                                <p style={{ fontSize: '14px' }} className="text-secondary">{each.positions}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="student-dashboard-top-jobs-container d-flex justify-content-center py-5">
                        <div className="col-lg-6 text-center p-2 p-md-0">
                            <h1 className="student-dashboard-top-jobs-heading">10000+</h1>
                            <h2 className="student-dashboard-top-jobs-sub-heading">Browse From Our Top Jobs</h2>
                            <p className="student-dashboard-top-jobs-para mt-3">Discover your next opportunity with Naukri. Find openings in your area and apply with ease. Discover the latest opportunities in your field and take your career to the next level. 10000+ Job Postings.</p>
                        </div>
                    </div>
                    <div className="student-dashboard-millions-container p-md-4 p-2 py-5 d-lg-flex">
                        <div className="col-lg-6 p-lg-4">
                            <img src={Images.million_img} alt="img" className="student-dashboard-millions-img" />
                        </div>
                        <div className="col-lg-6 p-lg-4 py-5">
                            <h2><b>Millions of Jobs. Find the one that suits you.</b></h2>
                            <p className="text-secondary my-4">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 600,000 companies worldwide.</p>
                            <p className="text-secondary">✔ Bring to the table win-win survival</p>
                            <p className="text-secondary">✔ Capitalize on low hanging fruit to identify</p>
                            <p className="text-secondary">✔ But I must explain to you how all this Get Started</p>
                            <button type="button" className="btn btn-outline-primary my-4">Get Started</button>
                        </div>
                    </div>
                    <div className=" student-dashboard-howtoapply-container position-relative d-none d-md-block px-2">
                        <div className="student-dashboard-howtoapply-img-container position-absolute ">
                            <img src={Images.student_how_applybg} alt="img" className="student-dashboard-howtoapply-img" />
                        </div>
                        <div className="student-dashboard-howtoapply-text-container text-center  position-absolute d-flex flex-column  justify-content-center ">
                            <h6 className="text-danger"><b>APPLY PROCESS</b></h6>
                            <h2 className="text-white my-3"><b>How it works</b></h2>
                            <div className="d-flex justify-content-evenly mt-4 ">
                                <div className="col-md-3 text-center text-white p-4 student-dashboard-howtoapply-text-card">
                                    <img src={Images.jobs10} alt="img" className="student-dashboard-howtoapply-text-img" />
                                    <h5 className="my-3">1. Search a job</h5>
                                </div>
                                <div className="col-md-3 text-center text-white p-4 student-dashboard-howtoapply-text-card">
                                    <img src={Images.jobs11} alt="img" className="student-dashboard-howtoapply-text-img" />
                                    <h5 className="my-3">2. Apply for job</h5>
                                </div>
                                <div className="col-md-3 text-center text-white p-4 student-dashboard-howtoapply-text-card">
                                    <img src={Images.jobs12} alt="img" className="student-dashboard-howtoapply-text-img" />
                                    <h5 className="my-3">3. Get your job</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default StudentDashboard