import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export default function Ex() {
    const [state, setState] = useState({
        name: '',
        village: ''
    })
    const navigate = useNavigate()

    const abc = (e) => {
        const queryParameters = new URLSearchParams(window.location.search)
        const { name, value } = e.target
        if (name === 'name') {
            setState({ ...state, name: value })
        }
        else if (name === 'village') {
            setState({ ...state, village: value })
        }

        if (value !== '') {
            queryParameters.set(name, value)
            navigate({ search: queryParameters.toString() });
        }

    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const nameValue = queryParameters.get('name') || '';
        const villageValue = queryParameters.get('village') || '';
        setState({ ...state, name: nameValue, village: villageValue });
    }, [state]);


    return (
        <div className="container">
            <input type="checkbox" name="name" checked={state.name === 'shashi'} value='shashi' onChange={abc} />
            <input type="checkbox" name="name" checked={state.name === 'soumya'} value='soumya' onChange={abc} />
            <input type="checkbox" name="name" checked={state.name === 'vinni'} value='vinni' onChange={abc} />
            <input type="radio" name="village" checked={state.village === 'knr'} value='knr' onChange={abc} />
            <input type="radio" name="village" checked={state.village === 'hyd'} value='hyd' onChange={abc} />
        </div>
    )
}





