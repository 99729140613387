import React, { useState, useEffect } from "react";
import './index.css'
import StudentProfile from "../StudentProfile";
import Offcanvas from 'react-bootstrap/Offcanvas';
import 'reactjs-popup/dist/index.css';
import { HiOutlineUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { IoHomeOutline, IoBriefcaseOutline } from "react-icons/io5";
import Images from "../../Images";


const StudentNavbar = () => {

    const [offcanvasShow, setoffcanvasShow] = useState(false);
    const [sidebarLocation, setSidebarLocation] = useState()

    useEffect(() => {
        setSidebarLocation(window.location.pathname)
    }, [])


    const handleClose = () => setoffcanvasShow(false);
    const handleShow = () => setoffcanvasShow(true);

    return (
        <div>
            <nav className="hr-nav-lg-container fixed-top d-flex justify-content-between align-items-center">
                <img className="w-100 hr-nav-logo d-none d-lg-block" src={Images.NHRC_LOGO} alt="Logo" />
                <img className="w-100 hr-nav-logo d-lg-none" onClick={handleShow} src={Images.NHRC_LOGO} alt="Logo" />
                <StudentProfile />
            </nav>
            <Offcanvas show={offcanvasShow} className='admin-mobile-offcanvas-container w-75 d-lg-none' onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div >
                        <Link to='/student/dashboard' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/dashboard' ? 'active_path' : ''}`}>
                            <IoHomeOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                            <h6 className="student-sidebar-items-pre m-0">Dashboard</h6>
                        </Link>
                        <Link to='/student/all/jobs' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/all/jobs' ? 'active_path' : ''}`}>
                            <IoBriefcaseOutline color="#474d6a" className="student-sidebar-list-icon" size={20} />
                            <h6 className="student-sidebar-items-pre m-0">Jobs</h6>
                        </Link>
                        <Link to='/student/profile' className={`d-flex student-sidebar-list-item my-1 nav-link ${sidebarLocation === '/student/profile' ? 'active_path' : ''}`}>
                            <HiOutlineUsers color="#474d6a" className="student-sidebar-list-icon" size={20} />
                            <h6 className="student-sidebar-items-pre m-0">My Profile</h6>
                        </Link>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}
export default StudentNavbar


