import React, { useState, useEffect, useCallback } from "react";
import './index.css'
import TpoNavbar from "../TpoNavbar";
import TpoSidebar from "../TpoSidebar";
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import { IoSchoolOutline, IoBookOutline, IoBookmarkOutline, IoBriefcaseOutline, IoLocationOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../../admin/AdminFailureView";
import { BsBriefcase } from 'react-icons/bs'

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const TpoDashboard = () => {

    const [tpoData, setTpoData] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingTpoprofile = useCallback(async (tpoId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}tpo/get_post/${tpoId}/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedData = [...results].sort((a, b) => b.id - a.id)
            setTpoData(reversedData)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }, [])


    useEffect(() => {
        const tpo_login_token = Cookies.get('tpo_login_token')
        if (tpo_login_token) {
            const TpoToken = JSON.parse(tpo_login_token)
            gettingTpoprofile(TpoToken.id)
        }
    }, [gettingTpoprofile])

    const successView = () => {
        return (
            <>
                <div className="d-md-flex p-1">
                    <div className="col-lg-3 col-md-4 col-12 py-2">
                        <div className=" hr-dashboard-count-sub-card py-4 d-flex justify-content-evenly  ">
                            <div className="hr-dashboard-icon-container p-3">
                                <BsBriefcase color="#1967d2" size={40} />
                            </div>
                            <div className="p-1 ">
                                <h4 className="hr-dashboard-postjob-count">{tpoData.length}</h4>
                                <p style={{ color: '#474d6a' }} className="p-0 m-0" >Tpo Posted</p>
                            </div>
                        </div>
                    </div>
                </div>
                {tpoData?.slice(0, 6).map((each) => {
                    return (
                        <div key={each.id} className="col-lg-6 p-1">
                            <div className="bg-white  p-3 tpo-manage-sub-card-dashboard">
                                <p className="hr-alljobs-items-para1 my-1 ">  <IoSchoolOutline size={19} /> <span className=" px-1"> {each.collegeName} </span></p>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookOutline size={19} /> <span className=" px-1"> {each.branch} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBookmarkOutline size={19} /> <span className=" px-1"> {each.qualification} </span></p>
                                </div>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <IoBriefcaseOutline size={19} /> <span className=" px-1"> {each.jobType} </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 col-md-6">  <FiUsers size={19} /> <span className=" px-1"> {each.noOfStudent} </span></p>
                                </div>
                                <div className="d-flex">
                                    <p className="hr-alljobs-items-para1 my-1 col-12">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.collegeAddress} </span></p>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    const rednerApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="vh-100 w-100">
            <TpoNavbar />
            <div className="d-flex hr-alljobs-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <TpoSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-alljobs-details-container p-md-4 p-1 py-4">
                    <div className=" p-3 my-md-0 d-flex row">
                        {rednerApiStatus()}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default TpoDashboard