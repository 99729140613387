import React, { useEffect, useState } from "react";
import './index.css'
import HrNavbar from "../HrNavbar";
import HrSidebar from "../HrSidebar";
import Url from '../../Url/Url'
import PageLoader from '../../Loaders/PageLoader'
import AdminFailureView from '../../admin/AdminFailureView'
import { useParams } from "react-router-dom";


const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}


const HrApplicantView = () => {

    const [studentData, setStudentData] = useState()
    const [updatedData, setUpdatedData] = useState({})
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)
    const { id } = useParams()


    const gettingStudentprofile = async (studentId) => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}student/${studentId}/`
        const options = {
            method: 'GET',
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            setStudentData(results)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }

    useEffect(() => {
        gettingStudentprofile(id)
    }, [id])


    const profileUpdateInputFields = (event) => {
        const { name, value } = event.target
        setUpdatedData({ ...updatedData, [name]: value })
    }


    const successView = () => {
        return (
            <div className="hr-profile-view-inputs-container p-md-4 p-3 my-3">
                {studentData && (
                    <div >
                        <img src={studentData.profilePic} alt="Profile Pic" className="hr-profile-view-pic" />
                        <div className="d-flex row">
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Full Name</label>
                                <input type="text" name="fullName" disabled placeholder={studentData.fullName} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Gender</label>
                                <input type="text" name="gender" disabled placeholder={studentData.gender} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Date of Birth</label>
                                <input type="date" name="dateOfBirth" disabled placeholder={studentData.dateOfBirth} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">State</label>
                                <input type="text" name="state" disabled placeholder={studentData.state} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">District</label>
                                <input type="text" name="district" disabled placeholder={studentData.district} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Pincode</label>
                                <input type="number" name="pincode" disabled placeholder={studentData.pincode} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Email</label>
                                <input type="email" name="personalMail" disabled placeholder={studentData.personalMail} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Mobile Number</label>
                                <input type="text" name="mobileNumber" disabled placeholder={studentData.mobileNumber} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">University Name</label>
                                <input type="text" name="universityName" disabled placeholder={studentData.universityName} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">College Name</label>
                                <input type="text" name="collegeName" disabled placeholder={studentData.collegeName} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Qualification</label>
                                <input type="text" name="qualification" disabled placeholder={studentData.qualification} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Department</label>
                                <input type="text" name="department" disabled placeholder={studentData.department} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Year Of Passout</label>
                                <input type="number" name="passOut" disabled placeholder={studentData.passOut} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                            <div className="hr-profile-view-input-container col-lg-6 my-2">
                                <label className="hr-profile-view-label my-1">Location</label>
                                <input type="text" name="location" disabled placeholder={studentData.location} onChange={profileUpdateInputFields} className="hr-profile-view-input-disabled w-100" />
                            </div>
                        </div>
                    </div>
                )}

            </div>
        )
    }

    const renderApiStatusView = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }


    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-2 p-1 py-2">
                    {renderApiStatusView()}

                </div>
            </div>
        </div >
    )
}
export default HrApplicantView