import React, { useEffect, useState } from "react";
import './index.css'
import AdminSidebar from "../AdminSidebar";
import { BsCurrencyRupee } from "react-icons/bs";
import { IoLocationOutline, IoDocumentTextOutline } from "react-icons/io5";
import { BiBookmark } from "react-icons/bi";
import { TbBriefcase2 } from "react-icons/tb";
import Url from '../../Url/Url'
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../AdminFailureView";
import DOMPurify from "dompurify";
import { Link } from "react-router-dom";

const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const AdminCareers = () => {

    const [hrJobList, setHrJobList] = useState()
    const [filterdHrJobList, setFilterdHrJobList] = useState()
    const [approvedStatus, setApprovedStatus] = useState('false')
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)



    const gettingHrJobPost = async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const url = `${Url}hr/jobposts/`
        const option = {
            method: 'GET'
        }
        const response = await fetch(url, option)
        const results = await response.json()
        if (response.ok === true) {
            setApiStatus(currentApiStatuses.success)
            const reversedResults = [...results].sort((a, b) => b.id - a.id);
            setHrJobList(reversedResults)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }
    }

    useEffect(() => {
        gettingHrJobPost()
    }, [])


    const gettingActiveorNot = (deadline) => {
        const today = new Date().toJSON().slice(0, 10)
        const results = today > deadline
        return (
            <div className={` d-flex flex-column  justify-content-center  ${results ? 'hr-jobpost-expired-text-div' : 'hr-jobpost-active-text-div'}`}>
                <p className=" my-0">
                    {results ? 'Expired' : 'Active'}
                </p>
            </div>
        )
    }

    const jobPostAdminApprove = async (ApproveId) => {
        const url = `${Url}admin/approve/${ApproveId}/`
        const option = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                approved: true
            })
        }
        const response = await fetch(url, option)
        if (response.ok === true) {
            alert("Job Post Approved!")
            const updatedData = filterdHrJobList?.filter((each) => each.id !== ApproveId)
            setFilterdHrJobList(updatedData)
        }
        else if (response.ok === false) {
            alert("Job Post Fail!")
        }
    }
    const adminJobDelete = async (deleteId) => {
        const url = `${Url}hr/jobposts/delete/${deleteId}/`
        const option = {
            method: 'DELETE',
        }
        const response = await fetch(url, option)
        if (response.ok === true) {
            const updatedData = filterdHrJobList?.filter((each) => each.id !== deleteId)
            setFilterdHrJobList(updatedData)
        }
        else if (response.ok === false) {
            alert("Job Post Delete Fail!")
        }
    }

    const approvedStatusSelect = (event) => {
        setApprovedStatus(event.target.value)
    }

    useEffect(() => {
        const approvedJobsList = (hrJobList ?? []).filter((each) => {
            return approvedStatus === 'true' ? each.approved === true : each.approved === false;
        });
        setFilterdHrJobList(approvedJobsList)
    }, [approvedStatus, hrJobList])

    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }

    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }

    const successView = () => {
        return (
            <div className="px-2 row">
                {filterdHrJobList?.map((each, index) => {
                    return (
                        <div key={index} style={{ color: '#474d6a', textDecoration: 'none' }} className="mb-1 col-lg-6 p-2 ">
                            <div className="hr-alljobs-items-container p-3 bg-white">
                                <div className="d-flex justify-content-between ">
                                    <div>
                                        <h6 className="text-dark hr-alljobs-items-para1"><b>{each.jobTitle}</b></h6>
                                        <h6 className="text-dark hr-alljobs-items-para1"><b>{each.companyName}</b></h6>
                                    </div>
                                    <div className="hr-alljobs-items-img-container">
                                        <img src={each.companylogo} alt="img" className="hr-alljobs-items-img" />
                                    </div>
                                </div>
                                <div className="d-flex row my-2">
                                    <p className="hr-alljobs-items-para my-1 col-5 col-md-3">  <TbBriefcase2 size={19} /> <span className=" px-1"> {each.experienceLevel} Yrs </span></p>
                                    <p className="hr-alljobs-items-para my-1 px-2  col-7 col-md-4">  <BsCurrencyRupee size={19} /> <span className=" px-1"> {each.salaryFrom} - {each.salaryEnd} PA </span></p>
                                    <p className="hr-alljobs-items-para1 my-1 px-2 col-12 col-md-5">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.state}, {each.district}</span></p>
                                    <div className="d-flex my-1">
                                        <div>
                                            <IoDocumentTextOutline size={19} />
                                        </div>
                                        {getJobDescriptionData(each.jobDescription)}
                                    </div>
                                    <div className="d-flex my-1">
                                        <div>
                                            <BiBookmark size={19} />
                                        </div>
                                        {getSkillsRequiredData(each.skillsRequired)}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between hr-alljobs-items-days">
                                    <>
                                        {gettingActiveorNot(each.applicationDeadline)}
                                    </>
                                    <div className="d-flex">
                                        {each.approved === false && (
                                            <button type="button" className="btn btn-outline-primary btn-sm" onClick={() => { jobPostAdminApprove(each.id) }}>Approve</button>
                                        )}

                                        <button type="button" className="btn btn-outline-danger btn-sm mx-2" onClick={() => { window.confirm('Are you sure you want to delete?',) && adminJobDelete(each.id) }}>Delete</button>
                                        <span className="d-flex flex-column  justify-content-center"><Link to={`/admin/hr/job/${each.id}`} target='_blank' >Details ➤  </Link></span>

                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    }

    const renderApiViews = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }

    return (
        <div className="admin-db-container d-flex">
            <>
                <AdminSidebar />
            </>
            <div className="col-lg-10 col-12 mt-5 py-3 my-lg-0 py-lg-0  admin-db-main-items-container">
                <div className="d-flex justify-content-between align-items-center ">
                    <h3 className="m-4"><b>HR Jobs</b></h3>
                    <div className="mx-2">
                        <select style={{ fontSize: '13px' }} className="form-select shadow-none" onChange={approvedStatusSelect} >
                            <option value='false'>Not Approved</option>
                            <option value='true'>Approved</option>
                        </select>
                    </div>
                </div>
                {renderApiViews()}
            </div>
        </div>
    )
}
export default AdminCareers