import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import './index.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegCircleUser } from 'react-icons/fa6'
import { IoMdPower } from 'react-icons/io'
import { IoMdArrowDropdown } from "react-icons/io";
import Modal from 'react-bootstrap/Modal';
import Images from "../../Images";
import Cookies from "js-cookie";

const HrProfile = () => {

    // const [apiStatus1, setApiStatus1] = useState(currentApiStatuses.initial)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    // const [old_password, setOld_password] = useState('')
    // const [new_password, setNew_password] = useState('')
    const navigate = useNavigate()

    const hrLogout = () => [
        Cookies.remove('hr_login_token'),
        navigate('/')
    ]

    return (
        <Dropdown >
            <Dropdown.Toggle className="dropdown-one nav-profile-container" id="dropdown-basic-two" >
                <img className="nav-profile" alt='img' src={Images.admin_profile} />
                <IoMdArrowDropdown color="#000" size={15} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-profile-width">
                <Dropdown.Item className="p-1">
                    <Link to='/hr/profile' className="nav-link">
                        <h6 className="d-flex align-items-center my-1 "> <FaRegCircleUser size={18} className="mx-2" />Your Profile</h6>
                    </Link>
                </Dropdown.Item>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form >
                            <div className="mb-2">
                                <label htmlFor="oldPassword" className="form-label">Old Password</label>
                                <input type="password" name="oldPassword" required className="form-control shadow-none" />
                            </div>
                            <div className="mb-2">
                                <label htmlFor="newPassword" className="form-label">New Password</label>
                                <input type="password" name="newPassword" required className="form-control shadow-none" />
                            </div>
                            <button type="submit" className="btn btn-primary btn-sm my-3 float-end ">Change</button>
                        </form>
                    </Modal.Body>
                </Modal>
                <Dropdown.Divider />
                <Dropdown.Item className="p-2 py-1">
                    <h6 className="d-flex align-items-center m-0 " onClick={hrLogout}> <IoMdPower size={19} className="mx-2" /> Sign Out</h6>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default HrProfile