import React, { useEffect, useState } from "react";
import './index.css'
import { Link } from "react-router-dom";
import { IoHomeOutline, IoSendOutline, IoBriefcaseOutline } from "react-icons/io5";
import { HiOutlineUsers } from "react-icons/hi2";

const TpoSidebar = () => {
    const [sidebarLocation, setSidebarLocation] = useState()

    useEffect(() => {
        setSidebarLocation(window.location.pathname)
    }, [])

    return (
        <div className="hr-sidebar-main-container py-4 px-3 col-lg-2 ">
            <Link to='/tpo/dashboard' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/tpo/dashboard' ? 'active_path' : ''}`}>
                <IoHomeOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Dashboard</h6>
            </Link>
            <Link to='/tpo/post' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/tpo/post' ? 'active_path' : ''}`}>
                <IoSendOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Tpo Post</h6>
            </Link>
            <Link to='/tpo/all/posts' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/tpo/all/posts' ? 'active_path' : ''}`}>
                <IoBriefcaseOutline color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">Manage Posts</h6>
            </Link>
            <Link to='/tpo/profile' className={`d-flex hr-sidebar-list-item my-1 nav-link ${sidebarLocation === '/tpo/profile' ? 'active_path' : ''}`}>
                <HiOutlineUsers color="#474d6a" className="hr-sidebar-list-icon" size={20} />
                <h6 className="hr-sidebar-items-pre m-0">My Profile</h6>
            </Link>
        </div>
    )
}
export default TpoSidebar