import React, { useState } from "react";
import './index.css'
import HrSidebar from "../HrSidebar";
import HrNavbar from '../HrNavbar'
import { BsBriefcase, BsCurrencyRupee } from "react-icons/bs";
import { IoLocationOutline, IoDocumentTextOutline } from "react-icons/io5";
import { BiBookmark } from "react-icons/bi";
import { TbBriefcase2 } from "react-icons/tb";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useCallback } from "react";
import Url from '../../Url/Url'
import Cookies from "js-cookie";
import DOMPurify from "dompurify";
import PageLoader from "../../Loaders/PageLoader";
import AdminFailureView from "../../admin/AdminFailureView";


const currentApiStatuses = {
    initial: 'INITIAL',
    success: 'SUCCESS',
    failure: 'FAILURE',
    inProgress: 'IN_PROGRESS',
}

const HrDashboard = () => {

    const [hrJobList, setHrJobList] = useState()
    const [apiStatus, setApiStatus] = useState(currentApiStatuses.initial)

    const gettingPostDays = (jobPostDate) => {
        const formattedDate = jobPostDate && jobPostDate.slice(8, 10) + '-' + jobPostDate.slice(5, 7) + '-' + jobPostDate.slice(0, 4);
        return formattedDate
    };

    const gettingActiveorNot = (deadline) => {
        const today = new Date().toJSON().slice(0, 10)
        const results = today > deadline
        return (
            <div className={`${results ? 'hr-jobpost-expired-text-div' : 'hr-jobpost-active-text-div'}`}>
                <p className=" my-0 ">
                    {results ? 'Expired' : 'Active'}
                </p>
            </div>
        )
    }

    const getJobDescriptionData = (jobDescription) => {
        const jobDescriptionClean = DOMPurify.sanitize(jobDescription);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: jobDescriptionClean }} />
    }

    const getSkillsRequiredData = (skillsRequired) => {
        const skillsRequiredClean = DOMPurify.sanitize(skillsRequired);
        return <span className="hr-alljobs-items-para1 px-2" dangerouslySetInnerHTML={{ __html: skillsRequiredClean }} />
    }

    const gettingHrDshboarddata = useCallback(async () => {
        setApiStatus(currentApiStatuses.inProgress)
        const hr_login_token = Cookies.get('hr_login_token')
        const HrTokenObj = JSON.parse(hr_login_token)
        const url = `${Url}hr/dashboard/${HrTokenObj.id}/`
        const options = {
            method: 'GET'
        }
        const response = await fetch(url, options)
        const results = await response.json()
        if (response.ok === true) {
            const reversedResults = [...results].sort((a, b) => b.id - a.id);
            setHrJobList(reversedResults)
            setApiStatus(currentApiStatuses.success)
        }
        else {
            setApiStatus(currentApiStatuses.failure)
        }

    }, [])

    useEffect(() => {
        gettingHrDshboarddata()
    }, [gettingHrDshboarddata])



    const successView = () => {
        return (
            <>
                <div className="">
                    <h4 style={{ letterSpacing: '1px' }} className="p-0 mt-3 m-2"><b>Dashboard</b></h4>
                    <div className="d-md-flex">
                        <div className="col-lg-3 col-md-4 col-12 p-2">
                            <div className=" hr-dashboard-count-sub-card py-4 d-flex justify-content-evenly  ">
                                <div className="hr-dashboard-icon-container p-3">
                                    <BsBriefcase color="#1967d2" size={40} />
                                </div>
                                <div className="p-1 ">
                                    <h4 className="hr-dashboard-postjob-count">{hrJobList?.length}</h4>
                                    <p style={{ color: '#474d6a' }} className="p-0 m-0" >Posted Jobs</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-4 col-12 p-2">
                                <div className=" hr-dashboard-count-sub-card py-4 d-flex justify-content-evenly  ">
                                    <div className="hr-dashboard-icon1-container p-3">
                                        <GrDocumentText color="#d93025" size={40} />
                                    </div>
                                    <div className="p-1 ">
                                        <h4 className="hr-dashboard-application-count">9382</h4>
                                        <p style={{ color: '#474d6a' }} className="p-0 m-0">Application</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-12 p-2">
                                <div className=" hr-dashboard-count-sub-card py-4 d-flex justify-content-evenly  ">
                                    <div className="hr-dashboard-icon2-container p-3">
                                        <FaRegBookmark color="#34a853" size={40} />
                                    </div>
                                    <div className="p-1 ">
                                        <h4 className="hr-dashboard-shortlist-count">32</h4>
                                        <p style={{ color: '#474d6a' }} className="p-0 m-0">Posted Jobs</p>
                                    </div>
                                </div>
                            </div> */}

                    </div>
                </div>
                <div className="hr-dashboard-recent-jobs-container m-2 my-4 p-3 p-md-4">
                    <h6 style={{ letterSpacing: '0.5px' }} className="p-1"><b>Recent Posts</b></h6>
                    <div className=" px-md-2 row">
                        {hrJobList?.slice(0, 6).map((each, index) => {
                            return (
                                <Link to={`/hr/job/details/${each.id}`} key={index} style={{ color: '#474d6a', textDecoration: 'none' }} className="mb-3 col-lg-6 p-2 ">
                                    <div className="hr-alljobs-items-container p-3 bg-white">
                                        <div className="d-flex justify-content-between ">
                                            <div>
                                                <h6 className="text-dark hr-alljobs-items-para1"><b>{each.jobTitle}</b></h6>
                                                <h6 className="text-dark hr-alljobs-items-para1"><b>{each.companyName}</b></h6>
                                            </div>
                                            <div className="hr-alljobs-items-img-container">
                                                <img src={each.companylogo} alt="img" className="hr-alljobs-items-img" />
                                            </div>
                                        </div>
                                        <div className="d-flex row my-2">
                                            <p className="hr-alljobs-items-para my-1 col-5 col-md-3">  <TbBriefcase2 size={19} /> <span className=" px-1"> {each.experienceLevel} Yrs </span></p>
                                            <p className="hr-alljobs-items-para my-1 px-2  col-7 col-md-4">  <BsCurrencyRupee size={19} /> <span className=" px-1"> {each.salaryFrom} - {each.salaryEnd} PA </span></p>
                                            <p className="hr-alljobs-items-para1 my-1 px-2 col-12 col-md-5">  <IoLocationOutline size={19} /> <span className=" px-1"> {each.state}, {each.district}</span></p>
                                            <div className="d-flex my-1">
                                                <div>
                                                    <IoDocumentTextOutline size={19} />
                                                </div>
                                                {getJobDescriptionData(each.jobDescription)}
                                            </div>
                                            <div className="d-flex my-1">
                                                <div>
                                                    <BiBookmark size={19} />
                                                </div>
                                                {getSkillsRequiredData(each.skillsRequired)}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between hr-alljobs-items-days">
                                            <p className="text-secondary my-0 px-1">
                                                {gettingPostDays(each.approved_date)}
                                            </p>
                                            <>
                                                {gettingActiveorNot(each.applicationDeadline)}
                                            </>

                                        </div>
                                    </div>
                                </Link>
                            )
                        })}

                    </div>
                </div>
            </>
        )
    }

    const renderApiStatus = () => {
        switch (apiStatus) {
            case currentApiStatuses.inProgress:
                return <PageLoader />
            case currentApiStatuses.success:
                return <>{successView()}</>
            case currentApiStatuses.failure:
                return <AdminFailureView />
            default:
                return null
        }
    }


    return (
        <div className="vh-100 w-100">
            <HrNavbar />
            <div className="d-flex hr-dashboard-content-container">
                <div className="col-lg-2 d-none d-lg-block">
                    <HrSidebar />
                </div>
                <div className="col-lg-10 col-12 hr-dashboard-details-container p-md-3 p-1 py-4">
                    {renderApiStatus()}
                </div>
            </div>
        </div >
    )
}
export default HrDashboard