import React from "react";
import { useNavigate, Link } from "react-router-dom";
import './index.css'
import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegCircleUser } from 'react-icons/fa6'
import { IoMdPower } from 'react-icons/io'
import { IoMdArrowDropdown } from "react-icons/io";
import Images from "../../Images";
import Cookies from "js-cookie";

const TpoProfile = () => {
    const navigate = useNavigate()

    const tpoLogout = () => [
        Cookies.remove('tpo_login_token'),
        navigate('/')
    ]

    return (
        <Dropdown >
            <Dropdown.Toggle className="dropdown-one nav-profile-container" id="dropdown-basic-two" >
                <img className="nav-profile" src={Images.admin_profile} alt='img' />
                <IoMdArrowDropdown color="#000" size={15} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="nav-profile-width">
                <Dropdown.Item className="p-1">
                    <Link to='/tpo/profile' className="nav-link">
                        <h6 className="d-flex align-items-center my-1 "> <FaRegCircleUser size={18} className="mx-2" />Your Profile</h6>
                    </Link>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="p-2 py-1">
                    <h6 className="d-flex align-items-center m-0 " onClick={tpoLogout}> <IoMdPower size={19} className="mx-2" /> Sign Out</h6>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
export default TpoProfile